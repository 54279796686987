import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
// import './style.css';
import System from "./SystemFunction";
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
import Banner from './banner';
import MoblieMenu from "./mobilemenu";
import TextSlider from './textslide';

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            playerid: null,
            user_id: props.props.username,
            products_page_count: 1,
            loginstate: props.props.loginstate,
            firstname: null,
            lastname: null,
            alert: null,
        }
    }

    redeem = (e) => {
        e.preventDefault();
        this.checklogin();
        if (!this.state.loginstate) {
            var title = 'Not login!'
            var text = ' คุณไม่ได้ทำการ Login'
            var object = { title, text }
        }
    }

    async componentDidMount() {
        try {
            await instance.post("/api/v1/tabgamelist", {
                state: "66",
                System: Systems,
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        console.log(res.data.message);
                        this.setState({
                            categorylist: res.data.message
                        });
                    }
                    else {
                    }
                });
        }
        catch (ex) {
            // console.log(ex);
        }

    }

    render() {
        return (
            <>
                <div>
                    <main className="container p-0">
                        <div className="p-1 text-left container-xl text-white mt-1 my5 animated animatedFadeInUp fadeInUp">
                            {/**/}
                            <div className="clearfix">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h4 className="ps-2 mb-0 pt-1 f-22 text-title">
                                            <b>คาสิโน</b>
                                        </h4>
                                    </div>
                                    <div>
                                        <img role="button" alt="" className="w-icon-cr-bl-record-all" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt-1 pb-3 text-white w-scroll-height">
                            <div className="x-category-provider js-game-scroll-container js-game-container animated animatedFadeInUp fadeInUp">
                                <div className="-games-list-container container">
                                    <nav className="nav-menu" id="navbarProvider">
                                        <ul className="nav nav-pills row animated animatedFadeInUp fadeInUp">
                                            {this.state.categorylist.map((listitem, i) => (
                                                <li key={i} id={listitem.gameid} className="col-4 col-lg-4 col-md-4 nav-item card-width -random-container -game-casino-macro-container p-1 pb-0 mb-2">
                                                    <a href={"/listgameall/casino/" + listitem.partner}>
                                                        <div
                                                            className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable example"
                                                            data-status="-cannot-entry -untestable"
                                                        >
                                                            <div className="-inner-wrapper card-width-banner">
                                                                <div>
                                                                    {/**/}
                                                                    {/**/}
                                                                    {/**/}
                                                                </div>
                                                                <picture>
                                                                    <img
                                                                        role="button"
                                                                        className="w-100 hover-slot hoverTextCss"
                                                                        lazy="loaded"
                                                                        data-src={listitem.imagesec}
                                                                        src={listitem.imagesec}
                                                                    />
                                                                </picture>
                                                                <div className="-overlay d-none d-lg-block">
                                                                    <div className="-overlay-inner">
                                                                        <div className="-wrapper-container">
                                                                            <button href={"/listgameall/casino/" + listitem.partner} className="-btn -btn-play js-account-approve-aware"  >
                                                                                <i className="fas fa-play" />
                                                                                <span className="-text-btn">เข้าเล่น</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-title mt-1 mb-0 text-center text-color-name-game">
                                                                {listitem.description}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>

            </>

        );

    }
}
export default App;
