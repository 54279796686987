import React, { Component } from 'react'; //different
import './App.css';
import Footer from './footer';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rewardlist: [],
            activePage: 1,
            isActive: false,
            categorylist: [],
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            showpass: true,
            point: 0,
            balance: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            token: window.localStorage.getItem("token"),
            type: "",
            linelink: "",
            amount: "",
            remark: "",
            name: "",
            password: "",
            left: false,
        }
        this.login = this.login.bind(this);
    }
    changepass = (e) => {
        e.preventDefault();
        this.setState({
            showpass: this.state.showpass ? false : true,
        });
    }
    login = (e) => {
        e.preventDefault();
        let errors = this.validateLoginForm();
    }
    notify = (mode, msg) => {
        if (mode == "success") {
            toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "Warning") {
            toast.warning(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "error") {
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "info") {
            toast.info(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }
    validateLoginForm = async (e) => {
        const { username, password } = this.state;
        console.log(username, password);
        await instance.post("/api/v1/userlogin", {
            userid: username,
            password: password,
            System: Systems,
            Function: 'validateLoginForm-navbar.js'
        })
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    //////console.log(res.data.status);
                    if (res.data.status === 200) {
                        localStorage.setItem('auth_token', res.data.token)
                        localStorage.removeItem('cookie', '')
                        this.setState({
                            userid: res.data.message.member_id,
                            username: res.data.message.playerid,
                            firstname: res.data.message.firstname,
                            lastname: res.data.message.lastname,
                            point: res.data.message.Point,
                            balance: res.databalance,
                            loginstate: true,
                        });
                        window.location.href = '/';
                    }
                    else if (res.data.status === 401) {
                        this.notify("error", 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
                    }
                    else {
                        this.notify("error", 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
                        //   Bonn.alert("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง")
                    }
                }
                else {
                    this.setState({
                        firstname: null,
                        lastname: null,
                        point: null,
                        loginstate: false,
                    });
                    var title = 'Error!'
                    var text = 'Username นี้ทำการสมัครไปแล้ว'
                    var object = { title, text }
                   // this.CheckThisGoalError(object);
                }
            });
    }
    async componentDidMount() {

        try {
            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    this.setState({
                        linelink: datas[1].value,
                        System: res.data.tblcatagoryx[0].System
                    });
                }
            });
        }
        catch (error) {
            //////console.log();
        }
    }
    onChange = event => {
        let nam;
        let val;
        if (event.target.name === undefined) {
            // //////console.log("undefined", event.target);
            nam = event.name;
            val = event.value;
        } else {
            // //////console.log("event", event.target);
            nam = event.target.name;
            val = event.target.value;
        }
        this.setState({ [nam]: val });

    };

    render() {
        return (
            <>

                <form>
                    <div className="-x-input-icon mt-2 mb-2 flex-column">
                        <div className="input-group mb-1">
                            <span
                                className="input-group-text bg-border-input-icon"
                                id="basic-addon1"
                            >
                                <img
                                    className="-icon"
                                    alt="EZ Slot"
                                    width={15}
                                    height={15}
                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/c8d943f5-025b-4bee-ee2e-752b3e427d00/public"
                                />
                            </span>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                onChange={this.onChange}
                                className="form-control form-control-form bg-border-input"
                                maxLength={10}
                                minLength={10}
                                placeholder="เบอร์โทรศัพท์"
                                aria-label="เบอร์โทรศัพท์"
                            />
                        </div>
                    </div>
                    <div className="input-group mb-3" data-v-8cd0ec04="">
                        <span
                            className="input-group-text bg-border-input-icon"
                            data-v-8cd0ec04=""
                        >
                            <img
                                className="-icon"
                                alt="EZ Slot"
                                width={15}
                                height={15}
                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/396cd4b2-cf1f-4c91-8c8f-6da030595400/public"
                            />
                        </span>
                        <input
                            type={!this.state.showpass ? "text" : "password"}
                            id="password"
                            name="password"
                            onChange={this.onChange}
                            className="form-control form-control-form"
                            aria-label="รหัสผ่าน"
                            placeholder="รหัสผ่าน"
                            aria-describedby="basic-addon1"
                        />
                        <span
                            className="input-group-text bg-border-input-icon-eye"
                            id="change-eye"
                            role="button"
                            onClick={(e) => this.changepass(e)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-eye-slash"
                                viewBox="0 0 16 16"
                            >
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                            </svg>

                            <svg
                                style={{ display: (this.state.showpass) ? "none" : "" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-eye"
                                viewBox="0 0 16 16"
                            >
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                            </svg>

                        </span>
                    </div>
                    <div
                        className="text-theme -v2 text-right"
                        style={{ textAlign: "right" }}
                    >
                        <a role="button">ลืมรหัสผ่าน</a>
                    </div>
                    <div className="text-center mt-1">
                        <button className="btn -submit js-submit-accept-term v-login mt-lg-1 mt-0 w-100 p-2"
                            onClick={(e) => {
                                this.login(e)
                            }}>
                            <span>เข้าสู่ระบบ</span>
                        </button>
                    </div>
                </form>

                {/* <div className="-bottom ">
                    <div data-animatable="fadeInModal">
                        <div className="x-admin-contact ">
                            <span className="x-text-with-link-component">
                                <label className="-text-message ">พบปัญหา</label>
                                <a
                                    href={this.state.linelink}
                                    className="-link-message "
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <u>ติดต่อฝ่ายบริการลูกค้า</u>
                                </a>
                            </span>
                        </div>
                    </div>
                </div> */}
            </>
        );
    }
}
export default App;
