import axios from 'axios';
//require('dotenv').config({ path: `.env.${process.env.NODE_ENV}` })

var api_URL = 'http://localhost:7001'
if (process.env.NODE_ENV === "development") {
  api_URL = 'http://localhost:7001'
}
else if (process.env.NODE_ENV === "production") {
  api_URL = 'https://betner.co:7001'
}
const instance = axios.create({
  baseURL: api_URL
});

// const instance = axios.create({
//     baseURL: 'http://localhost:7001'
// });
console.log(process.env.NODE_ENV);
const AUTH_TOKEN = "123"
const System = "Betner"
instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export default instance;

// const test =
// {
//   "id": "212e51a5-81cf-4031-8f22-693fddc99e2e"
//   , "productId": "JILI"
//   , "username": "0321654987"
//   , "currency": "THB"
//   , "timestampMillis": 1717923530332,
//   "txns": [{
//     "id": "78808876188788895060087"
//     , "status": "SETTLED"
//     ,"roundId": "57305455170752183745738"
//     , "betAmount": 10
//     , "payoutAmount": 20, "gameCode": "14"
//     , "playInfo": "Hyper Burst", "isEndRound": true
//     , "isFeature": false
//     , "isFeatureBuy": false
//     , "isSingleState": true
//     , "skipBalanceUpdate": false
//     , "transactionType": "BY_ROUND"
//     , "turnOver": 0
//   }]
// }