import React, { Component } from 'react'; //different
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import './App.css';
import Footer from './footer';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import instance from "./axios-instance";
import { NumericFormat } from 'react-number-format';
import Systems from "./SystemFunction";
import Casino from "./casino";
import Slot from "./slot";
import Fishing from "./fising";
import Sport from "./sport";
import Lotto from "./lotto";
import Listgameall from "./linklistall";
import GameHit from "./gamehit";
import moment from 'moment';
import md5 from 'md5';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { LoopTwoTone } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
var settings = {
    dots: true,
    infinite: true,
    slidesToShow: (window.innerWidth <= 760) ? 1 : 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear"
};
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            trueaccount: null,
            products_page_count: 1,
            isActive: false,
            activeTab: 1,
            resetpass: 0,
            stats: {},
            reports: {},
            listacc: [],
            paymentlist: null,
            checkcredit: false,
            refer: 1,
            showmodal: false,
            historylist: [],
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            tblprofile: [],
            userid: null,
            afficiate: [],
            modalx: false,
            cashoutSet: [],
            modal: false,
            linkaff: "",
            point: 0,
            depositamount: 0,
            maxwithdrawstatus: 0,
            useradvice: [],
            afficiatetoday: [],
            afficiateall: [],
            balance: 0,
            counter: 300,
            qrcode: null,
            qrcodecheck: false,
            loginstate: false,
            firstname: null,
            lastname: null,
            activeTab: 1,
            alert: null,
            modalOpen: false,
            handleClose: false,
            proderedeemlist: [],
            token: window.localStorage.getItem("token"),
            type: "",
            prolistx: [],
            prodetail: [],
            username: "",
            tabselect: 0,
            showpassnew: false,
            showpasscf: false,
            showpassold: false,
            amount: "",
            remark: "",
            mainacc: "",
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            rankpoint: 0,
            limit: null,
            name: "",
            password: "",
            oldpassword: "",
            passwordcf: "",
            left: false,
            pathname: "",
            linelink: ""
        }
    }
    promotionlist = () => {
        instance.post("/api/v1/getpromotion", {
            System: Systems,
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    prolistx: res.data.message,
                });
            }
        }).catch(async (err) => {
            //   console.log(err);

            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        });
    }
    linkdeposit = async (event, num) => {
        if (event.target !== undefined) {
            this.setState({ linkdeposit: event.target.value });
        }

    }
    confirmdeposittrue = async (e, num) => {
        console.log(493, num)
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        if (token != null) {
            Username = decoded.message.playerid;
            await instance.post("/api/v1/redeemtrue", {
                username: Username,
                System: Systems,
                linkurl: this.state.linkdeposit
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                console.log(res.data);
                if (res.data.res.code == "SUCCESS") {
                    await Swal.fire({
                        title: 'success!',
                        text: res.data.res.message,
                        icon: 'success',
                        confirmButtonText: 'ตกลง'
                    })
                } else {
                    await Swal.fire({
                        title: 'error!',
                        text: res.data.res.message,
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    })
                }
            });
        }



    }
    async recheccredit(evt) {

        evt.currentTarget.className += " fa-spin";
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        this.setState({
            checkcredit: true,
            userid: decoded.message.playerid,
            username: decoded.message.playerid,
            firstname: decoded.message.firstname,
            lastname: decoded.message.lastname,
            loginstate: true,
        });
        try {
            // console.log(2);
            await instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'recheccredit-App.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fullname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                        checkcredit: false,
                    });
                }
                else if (res.data.status === 401) {
                    //   console.log(res.data);
                    localStorage.clear();
                    await this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            ////////console.log(ex);
        }
    }
    async opencliamcashout(evt, cityName) {
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await instance.post("/api/v1/cliamcashout", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                this.rechecklogin(Username, token);
                await this.notify("success", 'ทำรายการรับเงินเรียบร้อย');
            }
            else if (res.data.status === 201) {
                await this.notify("error", 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน');
                return false;
            }
            else if (res.data.status === 202) {
                await this.notify("error", 'ไม่สามารถทำรายการได้');
                return false;
            }

        }).catch(async (err) => {
            // console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        });
    }
    onChangexxxxx = (checked) => {
        console.log(128, checked)
        if (checked == 1) {
            if (this.state.showpassold) {
                this.setState({ showpassold: false });
            }
            else {
                this.setState({ showpassold: true });
            }
        }
        else if (checked == 2) {
            if (this.state.showpassnew) {
                this.setState({ showpassnew: false });
            }
            else {
                this.setState({ showpassnew: true });
            }
        }
        else if (checked == 3) {
            if (this.state.showpasscf) {
                this.setState({ showpasscf: false });
            }
            else {
                this.setState({ showpasscf: true });
            }
        }
    }

    onChangex = event => {
        // console.log("event", event.target.name);


        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            this.setState({ [nam]: val });
        }
    };

    checkurl = (e, url) => {
        window.history.pushState(null, null, url);
        const pathname = window.location.pathname.split("/")
        console.log(210, pathname);
        if (pathname.length > 1) {
            if (pathname[1] == "") {
                console.log(250, pathname[1]);
                this.setState({
                    tabselect: 0,
                });
            }
            else if (pathname[1] == "casino") {
                console.log(250, pathname[1]);
                this.setState({
                    tabselect: 10,
                });
            }
            else if (pathname[1] == "gamehit") {
                this.setState({
                    tabselect: 11,
                });
            }
            else if (pathname[1] == "fishing") {
                this.setState({
                    tabselect: 12,
                });
            }
            else if (pathname[1] == "slot") {
                console.log(101, pathname);
                this.setState({
                    tabselect: 13,
                });
            }
            else if (pathname[1] == "sport") {
                this.setState({
                    tabselect: 14,
                });
            }
            else if (pathname[1] == "event") {
                this.setState({
                    tabselect: 16,
                });
            }
            else if (pathname[1] == "lotto") {
                this.setState({
                    tabselect: 17,
                });
            }
            else if (pathname[1] == "withdraw") {
                this.setState({
                    tabselect: 2,
                });
            }
            else if (pathname[1] == "deposit") {
                this.setState({
                    tabselect: 1,
                });
            }
            else if (pathname[1] == "statement") {
                this.setState({
                    tabselect: 3,
                });
            }
            else if (pathname[1] == "profile") {
                this.setState({
                    tabselect: 8,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/overview") {
                this.setState({
                    tabselect: 4,
                    refer: 1
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/member") {
                this.setState({
                    tabselect: 4,
                    refer: 2
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/income-summary") {
                this.setState({
                    tabselect: 4,
                    refer: 3
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/share-network") {
                this.setState({
                    tabselect: 4,
                    refer: 4
                });
            }
            else if (pathname[1] == "rebate") {
                this.setState({
                    tabselect: 5,
                });
            }
            else if (pathname[1] == "promotion") {
                this.setState({
                    tabselect: 7,
                });
            }
            else if (pathname[1] == "listgameall") {
                this.setState({
                    tabselect: 15,
                });
            }
        }
        else {
            this.setState({
                tabselect: 0,
            });
        }
    }

    async calllisthistory(userid) {
        await instance.post("/api/v1/userwithdrawlist", {
            userid: userid,
            Cat_ID: 0,
            System: Systems,
            page: 1,
            type: 1
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                //  //console.log(datas.withdrawlist);
                this.setState({
                    historylist: datas.withdrawlist
                });

            }
            else {
                // this.CheckThisGoalError();
            }
        });

    }
    confirmdeposit = async (e, num) => {
        console.log(493, num)
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                if (num === 0) {
                    if (token != null) {
                        Username = decoded.message.playerid;
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            console.log(res.data.tblpaymentgatewayx)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.tblpaymentgatewayx.QRcode,
                                paymentlist: res.data.tblpaymentgatewayx,
                                listacc: res.data.tblmain,
                                counter: 300,
                                qrcodecheck: true,
                            });
                            await this.notify("success", 'กรุณาสแกน qrcode ภายใน 5 นาที');
                        });

                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await this.notify("warning", 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ');

            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }

        else if (num === 5) {
            document.getElementById("truemonney").click()
            //deposittruemoneyModal
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    onChange = async (event) => {
        console.log(event);
        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            if (nam === "withdrawamount") {
                if (this.state.maxwithdrawstatus === 1) {
                    this.setState({ [nam]: this.state.balance });
                    await this.notify("warning", 'บังคับถอนยอดทั้งหมด');
                }
                else if (Number(val) <= Number(this.state.balance)) {
                    this.setState({ [nam]: val });
                }
                else {
                    await this.notify("warning", 'ยอดถอนของท่านไม่เพียงพอในการถอน');

                }
            } else {
                this.setState({ [nam]: val });
            }

        }
    };
    submitwithdraw = async (e) => {
        console.log(this.state.withdrawamount, this.state.balance);
        if (parseFloat(this.state.withdrawamount) <= parseFloat(this.state.balance)) {
            if (parseFloat(this.state.withdrawamount) >= 10) {
                var token = localStorage.getItem('auth_token');
                if (token != null) {
                    var decoded = jwt_decode(token);
                    var Username = decoded.message.playerid;
                    await instance.post("/api/v1/userwithdraw", {
                        User_id: Username,
                        System: Systems,
                        amount: this.state.withdrawamount
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        console.log(res.data);
                        if (res.data.status === 200) {
                            await this.notify("success", 'รายการถอนเรียบร้อย');
                            window.location.href = '/statement';
                        }
                        else if (res.data.status === 203) {
                            await this.notify("error", 'ท่านติด Turnover อีก ' + res.data.amountturnover + " บาท");

                        }
                        else if (res.data.status === 207) {
                            await this.notify("error", 'ขั้นต่ำในการถอน ' + res.data.maxwithdraw + " บาท");

                        }
                        else {
                            await this.notify("error", "ไม่สามารถทำรายการถอนได้ โปรดแจ้งพนักงาน");
                        }
                    });
                }
                else {
                    await this.notify("error", 'รายการถอนมีปัญหา โปรดติดต่อพนักงาน');
                }
            }
            else {
                await this.notify("warning", 'ขั้นต่ำในการถอน 50 บาท');
            }
        }
        else {
            await this.notify("warning", 'ยอดถอนของท่านไม่เพียงพอในการถอน');
        }

    }
    resetpassword = async (e, type) => {
        e.preventDefault();
        const { phoneNumber, fname, password, oldpassword, passwordcf } = this.state;
        console.log(type, password, oldpassword, passwordcf);
        var token = localStorage.getItem('auth_token');
        if (password === passwordcf) {
            var Username = null;
            var decoded = null;
            if (type === 1 && token != null) {
                decoded = jwt_decode(token);
                Username = decoded.message.playerid;
                instance.post("/api/v1/resetpassword", {
                    username: Username,
                    oldpass: oldpassword,
                    System: Systems,
                    newpass: password,
                    type: type
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    if (res.data.status === 200) {
                        await this.notify("success", 'เปลี่ยนพาสเวิดเรียบร้อย');
                    }
                    else if (res.data.status === 201) {
                        await this.notify("error", 'คุณใส่รหัสเก่าผิดพลาด');
                    }
                    else if (res.data.status === 203) {
                        await this.notify("error", 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง');
                    }
                    else if (res.data.status === 202) {
                        await this.notify("error", 'คุณใช้พาสเวิดเดิม');
                    }
                });
            }
            else if (type === 2) {
                instance.post("/api/v1/resetpassword", {
                    username: phoneNumber,
                    oldpass: oldpassword,
                    newpass: password,
                    System: Systems,
                    type: type
                }).then(async (res) => {
                    if (res.data.status === 200) {
                        await this.notify("success", 'เปลี่ยนพาสเวิดเรียบร้อย');
                    }
                    else if (res.data.status === 201) {
                        await this.notify("error", 'คุณใส่รหัสเก่าผิดพลาด');
                    }
                    else if (res.data.status === 203) {
                        await this.notify("error", 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง');
                    }
                    else if (res.data.status === 202) {
                        await this.notify("error", 'คุณใช้พาสเวิดเดิม');
                    }
                });
            }
            else if (type === 3) {
                instance.post("/api/v1/resetpassword", {
                    username: phoneNumber,
                    oldpass: oldpassword,
                    newpass: password,
                    System: Systems,
                    type: type
                }).then(async (res) => {
                    if (res.data.status === 200) {
                        await this.notify("success", 'เปลี่ยนพาสเวิดเรียบร้อย');
                    }
                    else if (res.data.status === 201) {
                        await this.notify("error", 'คุณใส่รหัสเก่าผิดพลาด');
                    }
                    else if (res.data.status === 203) {
                        await this.notify("error", 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง');
                    }
                    else if (res.data.status === 202) {
                        await this.notify("error", 'คุณใช้พาสเวิดเดิม');
                    }
                });
            }
        }
        else {
            await this.notify("error", 'คุณใส่พาสเวิดไม่ตรงกัน');
        }
    }
    async opencliaminvite(evt, cityName) {
        console.log(cityName);
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await instance.post("/api/v1/cliaminvite", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                this.rechecklogin(Username, token);
                await this.notify("success", 'ทำรายการรับเงินเรียบร้อย');
            }
            else if (res.data.status === 201) {
                await this.notify("error", 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน');
                return false;
            }
            else if (res.data.status === 202) {
                await this.notify("error", 'ไม่สามารถทำรายการได้');
                return false;
            }

        }).catch(async (err) => {
            // console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        });
    }
    handleClick = async (e, price) => {
        console.log(price);
        if (price <= this.state.balance) {
            this.setState({
                withdrawamount: price
            });
        }
        else {
            await this.notify("warning", 'ยอดถอนของท่านไม่เพียงพอในการถอน');
        }
    }
    handleClickreposit = async (e, price) => {
        this.setState({
            depositamount: price
        });
    }
    redeempro = async (e, proid) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        //console.log(Username, proid);
        if (token != null) {
            this.setState({
                username: Username,
            });
            await instance.post("/api/v1/redeempromotion", {
                userid: Username,
                System: Systems,
                Proid: proid,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //console.log(res.data.status)
                if (res.data.status === 200) {
                    this.setState({
                        showmodal: false,
                    })
                }
                else if (res.data.status === 201) {
                    await this.notify("error", 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้');
                    e.preventDefault();
                    return false;
                }
                else if (res.data.status === 204) {
                    await this.notify("error", 'ท่านใช้สิทธวันนี้ครบแล้ว');
                    e.preventDefault();
                    return false;
                }
                else if (res.data.status === 202) {
                    await this.notify("error", 'ท่านใช้โปรมั่นชั่นนี้ไปแล้ว');
                    e.preventDefault();
                    return false;
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }
            });
        }
    }
    couponredeem = async (e, index) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            await instance.post("/api/v1/usercoupon", {
                User_id: Username,
                System: Systems,
                couponkey: this.state.couponkey,
                type: 1
            }).then(async (res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    await this.notify("success", 'ดำเนินการใช้คูปองเรียบร้อย');
                }
                else {
                    await this.notify("warning", 'ไม่มี Key นี้ในระบบ');
                }
            });
        }
        else {
            await this.notify("warning", 'คุณไม่ได้ล๊อคอิน');
        }

    }

    copy = async (e, accnumber) => {
        toast.success("คัดลอกเรียบร้อย", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });

        navigator.clipboard.writeText(accnumber)
    }
    async componentDidMount() {
        this.promotionlist();
        setInterval(() =>
            this.setState({
                counter: this.state.counter - 1
            })
            , 1000);
        try {
            const pathname = window.location.pathname.split("/")
            // console.log(989999, pathname.length);
            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    var min = res.data.tblcatagoryx[0].limitdeposit;
                    var max = res.data.tblcatagoryx[0].maxdeposit;
                    var maxwithdrawstatus = res.data.tblcatagoryx[0].withdrawmax
                    var limit = { min, max }
                    this.setState({
                        maxwithdrawstatus: maxwithdrawstatus,
                        linelink: datas[1].value,
                        webnamex: datas[7].value,
                        System: res.data.tblcatagoryx[0].System,
                        cashoutSet: res.data.tblcashoutsetting[0],
                        inviteSet: res.data.tblinvitedsetting[0],
                        datas: datas,
                        limit: limit,
                    });
                }
                else {
                    // this.CheckThisGoalError();
                }
            });
            console.log(455, pathname[1] + "/" + pathname[2])
            await instance.post("/api/v1/gettrue", {
                userid: Username,
                System: Systems,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                if (res.data.status === 200) {
                    console.log(res);
                    this.setState({
                        trueaccount: res.data.message.tbltruewallet[0],
                    });

                }
            }).catch(async (err) => {
            });
            if (pathname.length > 1) {

                if (pathname[1] == "casino") {
                    console.log(250, pathname[1]);
                    this.setState({
                        tabselect: 10,
                    });
                }
                else if (pathname[1] == "gamehit") {
                    this.setState({
                        tabselect: 11,
                    });
                }
                else if (pathname[1] == "fishing") {
                    this.setState({
                        tabselect: 12,
                    });
                }
                else if (pathname[1] == "slot") {
                    console.log(101, pathname);
                    this.setState({
                        tabselect: 13,
                    });
                }
                else if (pathname[1] == "sport") {
                    this.setState({
                        tabselect: 14,
                    });
                }
                else if (pathname[1] == "event") {
                    this.setState({
                        tabselect: 16,
                    });
                }
                else if (pathname[1] == "lotto") {
                    this.setState({
                        tabselect: 17,
                    });
                }
                else if (pathname[1] == "withdraw") {
                    this.setState({
                        tabselect: 2,
                    });
                }
                else if (pathname[1] == "deposit") {
                    this.setState({
                        tabselect: 1,
                    });
                }
                else if (pathname[1] == "statement") {
                    this.setState({
                        tabselect: 3,
                    });
                }
                else if (pathname[1] == "profile") {
                    this.setState({
                        tabselect: 8,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/overview") {
                    this.setState({
                        tabselect: 4,
                        refer: 1
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/member") {
                    console.log(63855555);
                    this.setState({
                        tabselect: 4,
                        refer: 2
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/income-summary") {
                    this.setState({
                        tabselect: 4,
                        refer: 3
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/share-network") {
                    this.setState({
                        tabselect: 4,
                        refer: 4
                    });
                }
                else if (pathname[1] == "rebate") {
                    this.setState({
                        tabselect: 5,
                    });
                }
                else if (pathname[1] == "promotion") {
                    this.setState({
                        tabselect: 7,
                    });
                }
                else if (pathname[1] == "listgameall") {
                    this.setState({
                        tabselect: 15,
                    });
                }
            }
            try {
                //await this.checkuseradvice("0915656414");
                console.log(1625656556);
                var token = localStorage.getItem('auth_token');
                if (token != null) {
                    var decoded = jwt_decode(token);
                    var Username = decoded.message.playerid;
                    await this.rechecklogin(Username, token);
                    this.setState({
                        userid: decoded.message.playerid,
                        username: decoded.message.playerid,
                        firstname: decoded.message.firstname,
                        lastname: decoded.message.lastname,
                        loginstate: true,
                    });
                }
                else {
                    this.setState({
                        loginstate: false,
                    });
                }
            }
            catch (error) {
                console.log(185222, error);
            }
            try {
                var token = localStorage.getItem('auth_token');
                //  //////console.log(token);
                if (token != null) {
                    var decoded = jwt_decode(token);
                    var Username = decoded.message.playerid;
                    await this.rechecklogin(Username, token);
                    await this.checkuseradvice(decoded.message.playerid);
                    this.setState({
                        userid: decoded.message.playerid,
                        username: decoded.message.playerid,
                        firstname: decoded.message.firstname,
                        lastname: decoded.message.lastname,
                        loginstate: true,
                    });
                }
                else {
                    this.setState({
                        loginstate: false,
                    });
                }
                if (token != null) {
                    var decoded = jwt_decode(token);
                    let Username = decoded.message.playerid;
                    instance.post("/api/v1/getqrcode", {
                        username: Username,
                        System: Systems,
                        amount: 50
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {

                        if (res.data.status === 200) {
                            this.setState({
                                mainacc: res.data.mainacc,
                                listacc: res.data.tblmain,
                                paymentlist: res.data.tblpaymentgatewayx,
                            });
                        }
                    }).catch(async (err) => {
                        console.log(err);
                    });
                }

                await instance.post("/api/v1/gettrue", {
                    userid: Username,
                    System: Systems,
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    if (res.data.status === 200) {
                        console.log(res);
                        this.setState({
                            trueaccount: res.data.message.tbltruewallet[0],
                        });

                    }
                }).catch(async (err) => {
                });
            }
            catch (error) {
                //////console.log();
            }
        }
        catch (error) {
            console.log(185222, error);
        }
    }


    checktab(e, index) {
        //console.log(index == 2 &&  this.state.qrcode != null);
        this.setState({
            tabselect: index,
        });


    }
    openAccout(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className += " -open";
        }
    }
    async checkuseradvice(username) {
        instance.post("/api/v1/checkuseradvice", {
            User_id: username,
            page: 1,
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                console.log("statsstatsstatsstats", res.data.message.reports);
                this.setState({
                    stats: res.data.message.stats,
                    reports: res.data.message.reports,
                    useradvice: res.data.message.useradvicelist
                });
            }
            else {
                // console.log(res.data);
                // this.CheckThisGoalError();
            }
        });
    }

    openAccoutcan(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className = "x-menu-account-list-sidebar";
        }
    }

    async rechecklogin(Username, token) {

        try {
            console.log(45555555555555555);
            const search = window.location.href;
            const paramx = new URLSearchParams(search);
            // const space = search.split("/")
            const space = window.location.host
            var userhash = md5(Username);
            //  const linkaff = "https://pgline88.com/?register=" + userhash;
            const linkaff = "https://" + space + "/register?reg=" + userhash;
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(res.data);

                if (res.data.status === 200) {
                    this.calllisthistory(Username);
                    localStorage.setItem('auth_token', res.data.token)
                    console.log(1067, res.data.userafftoday, res.data.useraffall);
                    this.setState({
                        rankpic: res.data.tblrank.image,
                        rankname: res.data.tblrank.name,
                        linkaff: linkaff,
                        rankob: res.data.tblrank,
                        rankpoint: res.data.rankpoint,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        tblprofile: res.data.message,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        afficiatetoday: res.data.userafftoday,
                        afficiateall: res.data.useraffall,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                console.log(err)
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }
    checkpro = async (e, proid) => {
        console.log(248, proid);
        var Username = "";
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            Username = decoded.message.playerid;
        }
        await instance.post("/api/v1/checkpromotion", {
            userid: Username,
            System: Systems,
            Proid: proid,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            console.log(res.data)
            if (res.data.status === 200) {
                //console.log(res.data);
                if (res.data.type === 0) {
                    this.setState({
                        showmodal: true,
                        prodetail: res.data.message.tblpromotions[0],
                    });
                }
                else if (res.data.type === 1) {
                    this.setState({
                        showmodal: true,
                        proderedeem: res.data.message.tblpromotionredeems,
                    });
                }



            }
            else if (res.data.status === 201) {
                this.setState({
                    showmodal: true,
                    prodetail: [],
                    picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
                });

            }
            else if (res.data.status === 202) {
                await this.notify("success", 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย');
                return false;
            }

        }).catch(async (err) => {
            //   console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        });
    }


    validateLogout = (e) => {
        localStorage.removeItem('auth_token', '')
        this.setState({
            userid: null,
            username: null,
            password: null,
            firstname: null,
            lastname: null,
            point: null,
            loginstate: false,
        });
    }
    logout = (e) => {
        // e.preventDefault();
        let errors = this.validateLogout();
        if (errors === true) {
            // alert("You are successfully signed in...");
            window.location.reload()
        } else {

            this.setState({
                errors: errors,
                formSubmitted: true
            });
            window.location.reload()
        }
    }
    waitnotify = async () => {
        setTimeout(() => { console.log('World!') }, 5000)
    }
    notify = async (mode, msg) => {
        console.log(mode, msg);
        if (mode == "success") {
            await toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "warning") {
            await toast.warning(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "error") {
            await toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "info") {
            await toast.info(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        await this.waitnotify();
    }
    async opencliamcashout(evt, cityName) {
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await instance.post("/api/v1/cliamcashout", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                this.rechecklogin(Username, token);
                this.notify("success", 'ทำรายการรับเงินเรียบร้อย');

            }
            else if (res.data.status === 201) {
                await this.notify("error", 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน');
                return false;
            }
            else if (res.data.status === 202) {
                await this.notify("error", 'ไม่สามารถทำรายการได้');
                return false;
            }

        }).catch(async (err) => {
            // console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        });
    }
    render() {
        // const pathname = this.state.pathname
        // console.log(325, this.state.loginstate);
        const { paymentlist, listacc, prodetail, picnonpro, cashoutSet, trueaccount, loginstate, prolistx, userid, accnumber, rankpoint, mainacc, username, point, firstname, lastname, balance, proderedeemlist, linkaff, tblprofile } = this.state
        var accnumberx;
        // console.log(cashoutSet );
        if (accnumber != null && accnumber != undefined) {
            accnumberx = accnumber[0];
        }
        return (
            <>
                <div className="row m-0">
                    <div className="col-sm-11 col-md-8 col-lg-6 offset-lg-3 col-md-6 offset-md-2 p-0 bg-header-full">
                        <div className="text-white">
                            <div className="mt-1 text-white padding-banner animated animatedFadeInUp fadeInUp p-0" style={{ display: this.state.loginstate ? "none" : "" }}>
                                <div className="m-2 px-0 card-width-banner" style={{ marginBottom: 2 }}>
                                    <section className="carousel" dir="ltr" aria-label="Gallery">
                                        <div className="carousel__viewport">
                                            <Slider {...settings}>
                                                <div
                                                    className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                                    data-slick-index={-2}
                                                    aria-hidden="true"
                                                    tabIndex={-1}

                                                >
                                                    <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                                        <picture>
                                                            <img
                                                                className="img-fluid -slick-item -item-9"
                                                                src="/build/web/igame-index-lobby-wm/img/0bcbaffe-0797-44c0-b6ae-87d3857ee711.png"
                                                                alt="First slide"
                                                                width={1200}
                                                                height={590}
                                                                style={{ borderRadius: "0.5rem !important" }}
                                                            />
                                                        </picture>
                                                    </div>
                                                </div>
                                                <div
                                                    className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                                    data-slick-index={-1}
                                                    aria-hidden="true"
                                                    tabIndex={-1}

                                                >
                                                    <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                                        <picture>
                                                            <img
                                                                className="img-fluid -slick-item -item-9"
                                                                src="/build/web/igame-index-lobby-wm/img/d074e774-3a72-45ff-bbdc-a8c70f088dde.png"
                                                                alt="First slide"
                                                                width={1200}
                                                                height={590}
                                                                style={{ borderRadius: "0.5rem !important" }}
                                                            />
                                                        </picture>
                                                    </div>
                                                </div>
                                            </Slider >
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className="m-0 mt-2 mb-3 animated animatedFadeInUp fadeInUp">
                                <div className="bg animated animatedFadeInUp fadeInUp">
                                    <div className="d-flex justify-content-start text-white input-group-text bg-marquee-hh align-items-center">
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                className="bi bi-megaphone"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z" />
                                            </svg>
                                            <b> &nbsp; ประกาศ :</b>
                                        </div>
                                        <div className="w-100">
                                            <div>
                                                <marquee scrolldelay={100} className="ancm-text-1">
                                                    Secret789 &nbsp; เว็บตรง ลิขสิทธิ์แท้ เจ้าแรกในไทย มั่นคง
                                                    ปลอดภัย 100% &nbsp; Secret789 &nbsp; เท่านั้น !
                                                </marquee>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row m-2 bg-container-app bg-logo" style={{ display: this.state.loginstate ? "" : "none" }} >
                                    <div className="col-3 pr-0 pt-2">
                                        <div className="text-white-credit-balance" id="Username">
                                            {username}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div id="walletBG-1" className="mt-sm-3">
                                            <div id="walletBG">
                                                <div id="walletInner" />
                                                <div className="wrapper">
                                                    <div className="content text-font-balance">
                                                        <p className="m-0">
                                                            <b>เครดิตคงเหลือ</b>
                                                        </p>
                                                        <h4>
                                                            <b>
                                                                <span className="mb-balance text-font-balance"><NumericFormat value={balance} displayType={'text'} thousandSeparator={true} /></span>
                                                                <small>฿</small>
                                                            </b>
                                                        </h4>
                                                    </div>
                                                </div>
                                                <button className="btn-refresh" type="button">
                                                    <a
                                                        onClick={(e) => {
                                                            this.recheccredit(e)
                                                        }}
                                                        role="button text-white-credit-balance" id="icon-refresh-credit">
                                                        <span>
                                                            <b>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={25}
                                                                    height={25}
                                                                    fill="currentColor"
                                                                    className={this.state.checkcredit ? "text-black bi bi-arrow-clockwise circle-svg" : "text-black bi bi-arrow-clockwise"}
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                                                                    />
                                                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                                                </svg>
                                                            </b>
                                                        </span>
                                                    </a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-white-credit-balance" style={{ display: this.state.loginstate ? "" : "none" }} >
                                    <div className="row m-0">
                                        <div className="col-12 mb-2">
                                            {/* <div className="d-flex justify-content-between pb-1" id="progress">
                                                <small>0</small>
                                                <small>
                                                    {" "}
                                                    (ยอดฝากรวม <span id="total_deposit">0</span> ฿ / 0%){" "}
                                                    <span className="badge-level">
                                                        <b>ไม่ระบุ</b>
                                                    </span>
                                                </small>
                                                <small id="max_deposit">0</small>
                                            </div> */}
                                            <div className="progress progress-sm">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    id="progress_bar"
                                                    aria-valuemin={0}
                                                    aria-valuemax={0}
                                                    style={{ width: "0%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col p-1">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/deposit")
                                                }}
                                                style={{ cursor: "pointer" }}
                                                className="btn btn-success btn-lg btn-block w-100 p-1">
                                                <small>
                                                    <b>ฝากเงิน</b>
                                                </small>
                                            </a>
                                        </div>
                                        <div className="col p-1">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/withdraw")
                                                }}
                                                style={{ cursor: "pointer" }}
                                                className="btn btn-danger btn-lg btn-block w-100 p-1">
                                                <small>
                                                    <b>ถอนเงิน</b>
                                                </small>
                                            </a>
                                        </div>
                                        <div className="col p-1">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/statement")
                                                }}
                                                style={{ cursor: "pointer" }}
                                                className="btn btn-primary btn-lg btn-block w-100 p-1">
                                                <small>
                                                    <b>ประวัติ</b>
                                                </small>
                                            </a>
                                        </div>
                                        {/**/}
                                    </div>
                                </div>
                                <div className="mt-3" style={{ display: this.state.tabselect == 1 ? "" : "none" }} >
                                    <div className="x-login-index animated animatedFadeInUp fadeInUp p-2">
                                        <div className="container">
                                            <h5 className="mt-1 text-left text-type">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={20}
                                                    fill="currentColor"
                                                    className="bi bi-bank2"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
                                                </svg>{" "}
                                                ประเภทการฝากเงิน
                                            </h5>
                                            <div className="row">
                                                <div className="col-12 mb-2 p-1" style={{ display: mainacc.length == 0 ? "none" : "" }}>
                                                    <a onClick={(e) => {
                                                        this.checktab(e, 20)
                                                    }}>
                                                        <img
                                                            role="button"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a60b173a-8c16-4231-d817-336b03695d00/public"
                                                            className="w-100 img-deposit"
                                                            alt=""
                                                        />
                                                    </a>
                                                </div>
                                                <div className="col-12 mb-2 p-1">
                                                    <a onClick={(e) => {
                                                        this.checktab(e, 21)
                                                    }}>
                                                        <img
                                                            role="button"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/694ac4ec-d688-46cc-fb1f-31b3bc366100/public"
                                                            className="w-100 img-deposit"
                                                            alt=""
                                                        />
                                                    </a>
                                                </div>
                                                <div className="col-12 p-0" style={{ display: paymentlist == null ? "none" : "" }}>
                                                    <a onClick={(e) => {
                                                        this.checktab(e, 22)
                                                    }}>
                                                        <img
                                                            role="button"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/21c09030-ae4c-4f98-7ba0-e84a51719a00/public"
                                                            className="w-100 img-deposit"
                                                            alt=""
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3" style={{ display: this.state.tabselect == 2 ? "" : "none" }}>
                                    <div className="x-login-index animated animatedFadeInUp fadeInUp p-2">
                                        <div className="container">
                                            <h5 className="mt-1 text-left text-type">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={20}
                                                    fill="currentColor"
                                                    className="bi bi-bank2"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
                                                </svg>{" "}
                                                ประเภทการถอนเงิน
                                            </h5>
                                            <div className="row">
                                                <div className="col-12 mb-2 p-1">
                                                    <a onClick={(e) => {
                                                        this.checktab(e, 23)
                                                    }}>
                                                        <img
                                                            role="button"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a60b173a-8c16-4231-d817-336b03695d00/public"
                                                            className="w-100 img-deposit"
                                                            alt=""
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mb-2 p-1">
                                                    <a onClick={(e) => {
                                                        this.checktab(e, 23)
                                                    }}>
                                                        <img
                                                            role="button"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/694ac4ec-d688-46cc-fb1f-31b3bc366100/public"
                                                            className="w-100 img-deposit"
                                                            alt=""
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="x-margin-top-v3" style={{ display: this.state.tabselect == 3 ? "" : "none" }}>
                                    <div className="animated animatedFadeInUp fadeInUp p-2">
                                        <div className="p-0 container animated animatedFadeInUp fadeInUp text-center">
                                            <div className="row m-0 text-center">
                                                <div className="col-12 p-0">
                                                    <div className="text-left mb-3">
                                                        <div className="-inner-wrapper text-left">
                                                            <h3 className="x-title-modal mx-auto text-center">
                                                                <b>รายการธุรกรรมการเงิน</b>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 p-2 text-center">
                                                    <div
                                                        data-v-02098bde=""
                                                        data-v-28a2bed6=""
                                                        className="tourneys__tabs row text-center"
                                                    >
                                                        <button
                                                            data-v-47c7b004=""
                                                            data-v-28a2bed6=""
                                                            type="button"
                                                            className="active button-tab button-tab_md button-tab_blue pl-3 pr-3 col-4 text-center"
                                                        >
                                                            <span
                                                                data-v-47c7b004=""
                                                                data-v-28a2bed6=""
                                                                className="pl-4-pr-4"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={16}
                                                                    height={16}
                                                                    fill="currentColor"
                                                                    className="bi bi-wallet2"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
                                                                </svg>{" "}
                                                                ทั้งหมด
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <div
                                                        data-v-ab1d19ba=""
                                                        className="auction-list animated animatedFadeInUp fadeInUp text-white-v1"
                                                    >
                                                        <div style={{ display: (this.state.historylist.length === 0) ? "" : "none" }} >
                                                            <div>
                                                                <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                                    <b>
                                                                        <img
                                                                            className="load-history"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                            alt=""
                                                                        />
                                                                        <div className="not-history font-history-menu-text p-2">
                                                                            <small>
                                                                                <b>ไม่พบรายการ...</b>
                                                                            </small>
                                                                        </div>
                                                                    </b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{ display: (this.state.historylist.length === 0) ? "none" : "" }}
                                                            data-v-ab1d19ba=""
                                                            className="auction-list animated animatedFadeInUp fadeInUp text-white-v1"
                                                        >
                                                            <div data-v-0bcfa847="" data-v-ab1d19ba="">
                                                                <div data-v-0bcfa847="" className="list__head">
                                                                    <span data-v-0bcfa847="">ประวัติทำรายการ </span>
                                                                    <span data-v-0bcfa847="">เวลา </span>
                                                                    <span data-v-0bcfa847="">จำนวนเงิน </span>
                                                                </div>
                                                                <div>
                                                                    {this.state.historylist.map((listitem, i) => (

                                                                        <div data-v-0bcfa847="" key={i} className="list__inner">
                                                                            <div>
                                                                                <div data-v-0bcfa847="" className="list-item">
                                                                                    <div data-v-0bcfa847="" className="list-item__user">
                                                                                        <div data-v-0bcfa847="" className="item-user">
                                                                                            <div
                                                                                                data-v-b41a49a6=""
                                                                                                data-v-0bcfa847=""
                                                                                                className="user item-user__userpick user--clickable"
                                                                                            >
                                                                                                <div data-v-b41a49a6="" className="user__wrap" />
                                                                                            </div>
                                                                                            <span
                                                                                                data-v-0bcfa847=""
                                                                                                className={(listitem.type === "BN") ? "*โบนัส" : (listitem.type === "DE") ? "item-user__betsize text-success" : "item-user__betsize text-danger"}                                                                                              >
                                                                                                <h5 className="m-0 font-list-history">{(listitem.type === "BN") ? "*โบนัส" : (listitem.type === "DE") ? "ฝากเงิน" : "ถอนเงิน"}</h5>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div data-v-0bcfa847="" className="list-item__user">
                                                                                        <div data-v-0bcfa847="" className="item-user">
                                                                                            <div
                                                                                                data-v-b41a49a6=""
                                                                                                data-v-0bcfa847=""
                                                                                                className="user item-user__userpick user--clickable"
                                                                                            >
                                                                                                <div data-v-b41a49a6="" className="user__wrap" />
                                                                                            </div>
                                                                                            <span data-v-0bcfa847="" className="item-user__name">
                                                                                                {moment(listitem.createdate).format('DD/MM/YY') + " - " + moment(listitem.createdate).format('HH:mm') + " น."}
                                                                                            </span>
                                                                                            <span
                                                                                                data-v-0bcfa847=""
                                                                                                className="item-user__betsize text-success"
                                                                                            >
                                                                                                <div style={{ display: (listitem.status === "2") ? "" : "none" }}>
                                                                                                    <span className="-state-text">สถานะ : </span>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-counterclockwise text-warning" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"></path><path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"></path></svg>
                                                                                                    {" "} <span className="-state-title text-warning">กำลังดำเนินการ</span>
                                                                                                </div>
                                                                                                <div style={{ display: (listitem.status === "0") ? "" : "none" }}>
                                                                                                    <span className="-state-text">สถานะ : </span>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-counterclockwise text-warning" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"></path><path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"></path></svg>
                                                                                                    {" "} <span className="-state-title text-warning">รอดำเนินการ</span>
                                                                                                </div>
                                                                                                <div style={{ display: (listitem.status === "4") ? "" : "none" }}>
                                                                                                    <span className="-state-text">สถานะ : </span>
                                                                                                    <img
                                                                                                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                                                                                        className="-ic"
                                                                                                        width="16" height="16"
                                                                                                        alt=""
                                                                                                    />
                                                                                                    {" "}
                                                                                                    <span className="-state-title">ยกเลิก (ยกเลิกโดยผู้เล่น)</span>
                                                                                                </div>
                                                                                                <div style={{ display: (listitem.status === "5") ? "" : "none" }}>
                                                                                                    <span className="-state-text">สถานะ : </span>
                                                                                                    <img
                                                                                                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                                                                                        className="-ic"
                                                                                                        width="16" height="16"
                                                                                                        alt=""
                                                                                                    />
                                                                                                    {" "}
                                                                                                    <span className="-state-title">ไม่สำเร็จ (โดยระบบ)</span>
                                                                                                </div>
                                                                                                <div style={{ display: (listitem.status === "1") ? "" : "none" }}>
                                                                                                    <span className="-state-text">สถานะ : </span>
                                                                                                    <img
                                                                                                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                                                                                        className="-ic"
                                                                                                        width="16" height="16"
                                                                                                        alt=""
                                                                                                    />
                                                                                                    {" "}
                                                                                                    <span className="-state-title">สำเร็จ</span>
                                                                                                </div>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div data-v-0bcfa847="" className="list-item__expected">
                                                                                        <div data-v-0bcfa847="" className="currency">
                                                                                            <span data-v-0bcfa847="">
                                                                                                <span
                                                                                                    className={(listitem.type === "DE") ? "font-digits text-success" : "font-digits text-danger"}
                                                                                                    style={{ color: "rgb(255, 255, 255)", fontSize: 22 }}
                                                                                                >
                                                                                                    {listitem.amount}
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container p-0 top-loss x-margin-top-v3" style={{ display: this.state.tabselect == 4 ? "" : "none" }}>
                                    <div className="m-0 border-shadow-title">
                                        <div>
                                            <div
                                                className="d-flex justify-content-between p-1 text-color bg-refer-title"
                                                style={{ width: "100%", overflowX: "scroll" }}
                                            >
                                                <div className="active-tap-hover">
                                                    <div
                                                        onClick={(e) => {
                                                            this.checkurl(e, '/refer/overview')
                                                        }}
                                                        role="button"
                                                        className={this.state.refer == 1 ? "text-color p-1 rounded-pill w-width-tap active-refer" : "text-color p-1 rounded-pill w-width-tap"}
                                                    >
                                                        <div data-v-ab1d19ba="" className="auction-width">
                                                            <div
                                                                data-v-ab1d19ba=""
                                                                className="auction__body-menu auction__body--border-radius"
                                                            >
                                                                <div data-v-ab1d19ba="" className="tab text-color">
                                                                    <div
                                                                        data-v-00ef951c=""
                                                                        className="auction-pool-menu text-center"
                                                                    >
                                                                        <span className="text-size-menu-casino">
                                                                            <b>ภาพรวม</b>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="active-tap-hover">
                                                    <div
                                                        onClick={(e) => {
                                                            this.checkurl(e, '/refer/member')
                                                        }}
                                                        role="button"
                                                        //className="text-color p-1 rounded-pill w-width-tap"
                                                        className={this.state.refer == 2 ? "text-color p-1 rounded-pill w-width-tap active-refer" : "text-color p-1 rounded-pill w-width-tap"}
                                                    >
                                                        <div data-v-ab1d19ba="" className="auction-width">
                                                            <div
                                                                data-v-ab1d19ba=""
                                                                className="auction__body-menu auction__body--border-radius"
                                                            >
                                                                <div data-v-ab1d19ba="" className="tab text-color">
                                                                    <div
                                                                        data-v-00ef951c=""
                                                                        className="auction-pool-menu text-center"
                                                                    >
                                                                        <span className="text-size-menu-casino">
                                                                            <b>สมาชิก</b>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="active-tap-hover">
                                                    <div
                                                        onClick={(e) => {
                                                            this.checkurl(e, '/refer/income-summary')
                                                        }}
                                                        role="button"
                                                        // className="text-color p-1 rounded-pill w-width-tap"
                                                        className={this.state.refer == 3 ? "text-color p-1 rounded-pill w-width-tap active-refer" : "text-color p-1 rounded-pill w-width-tap"}
                                                    >
                                                        <div data-v-ab1d19ba="" className="auction-width">
                                                            <div
                                                                data-v-ab1d19ba=""
                                                                className="auction__body-menu auction__body--border-radius"
                                                            >
                                                                <div data-v-ab1d19ba="" className="tab text-color">
                                                                    <div
                                                                        data-v-00ef951c=""
                                                                        className="auction-pool-menu text-center"
                                                                    >
                                                                        <span className="text-size-menu-casino">
                                                                            <b>สรุปรายได้</b>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="active-tap-hover">
                                                    <div
                                                        onClick={(e) => {
                                                            this.checkurl(e, '/refer/share-network')
                                                        }}
                                                        role="button"
                                                        className={this.state.refer == 4 ? "text-color p-1 rounded-pill w-width-tap active-refer" : "text-color p-1 rounded-pill w-width-tap"}
                                                    >
                                                        <div data-v-ab1d19ba="" className="auction-width">
                                                            <div
                                                                data-v-ab1d19ba=""
                                                                className="auction__body-menu auction__body--border-radius"
                                                            >
                                                                <div data-v-ab1d19ba="" className="tab text-color">
                                                                    <div
                                                                        data-v-00ef951c=""
                                                                        className="auction-pool-menu text-center"
                                                                    >
                                                                        <span className="text-size-menu-casino">
                                                                            <b>เครือข่าย</b>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container mb-5 mt-2">
                                        <div className="p-2 bg-profile-box-overview" style={{ display: this.state.tabselect == 4 && this.state.refer == 1 ? "" : "none" }}>
                                            <div className="animated animatedFadeInUp fadeInUp mt-2">
                                                <div className="bg-profile-box-overview p-2">
                                                    <div className="d-flex justify-content-between row m-0 align-items-center">
                                                        <div className="col-12 col-md-12 p-0">
                                                            <div className="d-flex justify-content-between elementor-element-play-overview text-center bg-profile-credit-bl p-2">
                                                                <div className="align-self-center">
                                                                    <div className="p-1 text-left">
                                                                        <div>
                                                                            <h3 className="m-0">
                                                                                <b className="p-1">  {this.state.reports && this.state.reports.lastMonth
                                                                                    ? this.state.reports.lastMonth.totalAmount
                                                                                    : 0}</b>
                                                                            </h3>
                                                                        </div>
                                                                        <small>รายได้จากเครือข่าย ทั้งหมด</small>
                                                                    </div>
                                                                </div>
                                                                <div className="align-self-center text-right">
                                                                    <div>
                                                                        <span className="badge text-bg-dark">{this.state.reports.percentageChange}%</span>
                                                                    </div>
                                                                    <div>
                                                                        <span className="font-play-text">จากเดือนที่แล้ว</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-2">
                                                    <div className="row m-0">
                                                        <div className="col-12 col-md-12 p-1">
                                                            <div className="d-flex justify-content-between financial-button">
                                                                <div
                                                                    role="button"
                                                                    className="align-self-center p-1 credit-bl-text"
                                                                >
                                                                    <div role="button" className="financial-button-r">
                                                                        <div className="d-flex justify-content-start">
                                                                            <div className="align-self-center">
                                                                                <img
                                                                                    className="logo-profile-user-w p-1"
                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/48a22d9a-8abe-477f-0f40-57204ce17700/public"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="align-self-center">
                                                                                <div className="username-profile">
                                                                                    <small>รายได้ที่ถอนได้ </small>
                                                                                </div>
                                                                                <div className="username-profile">
                                                                                    <small>
                                                                                        <b className="font-aff-wid">{(tblprofile.AffiliateCash && tblprofile.AffiliateCash > 0)
                                                                                            ? tblprofile.AffiliateCash
                                                                                            : 0}</b>
                                                                                    </small>
                                                                                    <a
                                                                                        role="button"
                                                                                        className="text-white-credit-balance p-1"
                                                                                        id="icon-refresh-credit"
                                                                                    >
                                                                                        <span>
                                                                                            <b>
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width={18}
                                                                                                    height={18}
                                                                                                    fill="currentColor"
                                                                                                    className="text-white bi bi-arrow-clockwise"
                                                                                                    viewBox="0 0 16 16"
                                                                                                >
                                                                                                    <path
                                                                                                        fillRule="evenodd"
                                                                                                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                                                                                                    />
                                                                                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                                                                                </svg>
                                                                                            </b>
                                                                                        </span>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="align-self-center">
                                                                    <a role="button"
                                                                        onClick={(e) => {
                                                                            this.opencliaminvite(e, 'bonusrankaccount')
                                                                        }}>
                                                                        <div className="bg-text-highlight pd-bl-aff">
                                                                            <div
                                                                                disabled="false"
                                                                                role="button"
                                                                                className="btn -submit js-submit-accept-term v-login mt-lg-1 mt-0 w-100 p-2"
                                                                            >
                                                                                <img
                                                                                    className="wid-button p-1"
                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/de26db72-10a7-4369-5e7e-dd07d4568300/public"
                                                                                    alt=""
                                                                                />
                                                                                <span> ถอนรายได้ </span>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row m-0 mt-2">
                                                        <div className="col-6 p-0">
                                                            <div className="elementor-element-play-overview p-2 m-1">
                                                                <div className="d-flex justify-content-between text-center">
                                                                    <div className="align-self-center">
                                                                        <div className="p-1 text-left">
                                                                            <img
                                                                                className="icon-aff"
                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5e4ede40-9f30-4c50-5278-136b8e2e0900/public"
                                                                                alt=""
                                                                            />
                                                                            <div>
                                                                                <h3 className="m-0">
                                                                                    <b>{this.state.stats.lastMonthSignups} </b>
                                                                                </h3>
                                                                            </div>
                                                                            <small role="button">จำนวนคลิกลิงค์ทั้งหมด</small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="align-self-center text-right">
                                                                        <div>
                                                                            <div>
                                                                                <small className="font-play-text">
                                                                                    จากเดือนที่แล้ว
                                                                                </small>
                                                                            </div>
                                                                            <span className="badge text-bg-dark">{this.state.stats.percentChange} %</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 p-0">
                                                            <div className="elementor-element-play-overview p-2 m-1">
                                                                <div className="d-flex justify-content-between text-center">
                                                                    <div className="align-self-center">
                                                                        <div className="p-1 text-left">
                                                                            <img
                                                                                className="icon-aff"
                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a7961b16-c14d-495f-3d2f-c7ea12ab2c00/public"
                                                                                alt=""
                                                                            />
                                                                            <div>
                                                                                <h3 className="m-0">
                                                                                    <b>{this.state.stats.todaySignups}</b>
                                                                                </h3>
                                                                            </div>
                                                                            <small role="button">จำนวนสมาชิกทั้งหมด</small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="align-self-center text-right">
                                                                        <div>
                                                                            <div>
                                                                                <small className="font-play-text">
                                                                                    จากเดือนที่แล้ว
                                                                                </small>
                                                                            </div>
                                                                            <span className="badge text-bg-dark">0.00%</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div onClick={(e) => {
                                                        this.checkurl(e, '/refer/member')
                                                    }} className="d-flex justify-content-between pt-2 pb-2">
                                                        <div role="button"

                                                            className="align-self-center">
                                                            <img
                                                                className="icon-profile-title-left"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/f8270235-43e5-4c38-247d-98b9ebfbe700/public"
                                                                alt=""
                                                            />
                                                            <small>
                                                                <b>&nbsp;สมาชิกใหม่วันนี้</b>
                                                            </small>
                                                        </div>
                                                        <div className="align-self-center">{this.state.stats.todaySignups}คน </div>
                                                    </div>
                                                    <hr className="m-0 color-hr" />
                                                    <div onClick={(e) => {
                                                        this.checkurl(e, '/statement')
                                                    }} className="d-flex justify-content-between pt-2 pb-2">
                                                        <div role="button"

                                                            className="align-self-center">
                                                            <img
                                                                className="icon-profile-title-left"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/f8270235-43e5-4c38-247d-98b9ebfbe700/public"
                                                                alt=""
                                                            />
                                                            <small>
                                                                <b>&nbsp;ประวัติ</b>
                                                            </small>
                                                        </div>
                                                        <div className="align-self-center">
                                                            <img
                                                                className="goto-financial"
                                                                role="button"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/ad650691-9c48-4cb9-6e44-36d5d1eaf700/public"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <hr className="m-0 color-hr" />
                                                    <div onClick={(e) => {
                                                        this.checkurl(e, '/refer/share-network')
                                                    }} className="d-flex justify-content-between pt-2 pb-2">
                                                        <div

                                                            role="button" className="align-self-center">
                                                            <img
                                                                className="icon-profile-title-left"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/f8270235-43e5-4c38-247d-98b9ebfbe700/public"
                                                                alt=""
                                                            />
                                                            <small>
                                                                <b>&nbsp;วิธีการสร้างเครือข่าย </b>
                                                            </small>
                                                        </div>
                                                        <div className="align-self-center">
                                                            <img
                                                                className="goto-financial"
                                                                role="button"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/ad650691-9c48-4cb9-6e44-36d5d1eaf700/public"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-2 animated animatedFadeInUp fadeInUp">
                                                <div className="mt-2 mb-2">
                                                    <img
                                                        className="icon-profile-title-left"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/81deef6a-eb5d-4bf2-221c-3b85586ca700/public"
                                                        alt=""
                                                    />
                                                    <small className="pt-2 pb-2">
                                                        <b>&nbsp; ลิงก์เชิญชวน</b>
                                                    </small>
                                                </div>
                                                <div className="bg-color-financial-my-bank bg-profile-box-overview">
                                                    <div className="d-flex justify-content-between pt-3 pb-3">
                                                        <div>
                                                            <small>คัดลอก</small>
                                                            <div>
                                                            </div>

                                                        </div>
                                                        {mainacc !== null && mainacc !== undefined && mainacc !== '' ?
                                                            <a style={{ cursor: "pointer" }} onClick={(e) => this.copy(e, linkaff)}>

                                                                <small className="font-play-text">
                                                                    <b>
                                                                        <small id="copy-ShareLink">
                                                                            {linkaff}
                                                                        </small>
                                                                        <img
                                                                            role="button"
                                                                            className="icon-profile-title-left"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/270b7b9f-b130-414c-b2a8-dfe9ecb9cc00/public"
                                                                            alt=""
                                                                        />
                                                                    </b>
                                                                </small>

                                                            </a>
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            {/**/}
                                            {/**/}
                                            {/**/}
                                        </div>
                                        <div className="p-2 bg-profile-box-overview" style={{ display: this.state.tabselect == 4 && this.state.refer == 2 ? "" : "none" }}>
                                            <div className="animated animatedFadeInUp fadeInUp">
                                                <div className="box-number text-center">
                                                    <div>
                                                        <div className="bg-profile-box-overview p-2 mb-2">
                                                            <div className="d-flex justify-content-between row m-0 align-items-center">
                                                                <div className="col-12 col-md-12 p-0">
                                                                    <div
                                                                        className="d-flex justify-content-between elementor-element-play-overview text-center bg-profile-credit-bl p-2">
                                                                        <div className="align-self-center">
                                                                            <div className="p-1 text-left"><small><img className="icon-aff"
                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/84f8435d-3a19-4e45-eb3c-57f582263300/public"
                                                                                alt="" /> ยอดแอคทีฟทั้งหมด</small>
                                                                                <div><label className="m-0 font-member-aff-all"><b className="p-1">{this.state.stats.totalSignups}</b></label> คน </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="align-self-center text-right">
                                                                            <div><span className="badge text-bg-dark">{this.state.stats.todaySignups}</span></div>
                                                                            <div><span className="font-play-text text_aff_affiliate">สมัครวันนี้</span></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <table className="table">
                                                                    <tbody>
                                                                        {this.state.afficiatetoday.map((listitem, i) => (
                                                                            <tr className="text-center cl-mod-1">
                                                                                <td className="p-1-aff" style={{ width: '50px' }}><img className="icon-aff-member"
                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                    alt="" /></td>
                                                                                <td className="text-left p-1-aff text_aff_affiliate"><b>{i + 1}</b></td>
                                                                                <td className="text-left p-1-aff text_aff_affiliate">{listitem.playerid}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                            <div className="col-12"><canvas id="acquisitions" style={{
                                                                display: 'block', boxSizing: 'border-box',
                                                            }} />
                                                            </div>
                                                            <div className="d-flex justify-content-between scroll-title-history-aff align-items-center mb-1 mt-1">
                                                                <div><a className="btn btn-outline-secondary text_aff_affiliate_tab text_aff_affiliate_tab_active"
                                                                    role="button">วันนี้</a></div>
                                                                <div><a className="btn btn-outline-secondary text_aff_affiliate_tab" role="button">7 วันที่เเล้ว</a></div>
                                                                <div><a className="btn btn-outline-secondary text_aff_affiliate_tab" role="button">เดือนที่เเล้ว</a></div>
                                                                <div><a className="btn btn-outline-secondary text_aff_affiliate_tab" role="button">ทั้งหมด</a></div>
                                                            </div>
                                                        </div>
                                                        <div className="row m-0">
                                                            <div className="col-12 col-md-6 p-1">
                                                                <div className="elementor-element-play-overview p-2">
                                                                    <div className="d-flex justify-content-between text-center">
                                                                        <div className="align-self-center">
                                                                            <div className="p-1 text-left"><img className="icon-aff"
                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                alt="" /> LV 1 <div><b className="m-0 font-member-aff">{this.state.afficiatetoday.length} </b><small className="text_aff_affiliate">
                                                                                    คน</small></div><small className="text_aff_affiliate">แนะนำสมาชิกทั้งหมด</small></div>
                                                                        </div>
                                                                        <div className="align-self-center text-right">
                                                                            <div>
                                                                                <div><small className="font-play-text text_aff_affiliate">จากเดือนที่แล้ว</small></div><span
                                                                                    className="badge text-bg-dark">0.00%</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-12 col-md-6 p-1">
                                                                <div className="elementor-element-play-overview p-2">
                                                                    <div className="d-flex justify-content-between text-center">
                                                                        <div className="align-self-center">
                                                                            <div className="p-1 text-left"><img className="icon-aff"
                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                alt="" /> LV 2 <div><b className="m-0 font-member-aff">0</b><small className="text_aff_affiliate">
                                                                                    คน</small></div><small className="text_aff_affiliate">แนะนำสมาชิกทั้งหมด</small></div>
                                                                        </div>
                                                                        <div className="align-self-center text-right">
                                                                            <div>
                                                                                <div><small className="font-play-text text_aff_affiliate">จากเดือนที่แล้ว</small></div><span
                                                                                    className="badge text-bg-dark">0.00%</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 p-1">
                                                                <div className="elementor-element-play-overview p-2">
                                                                    <div className="d-flex justify-content-between text-center">
                                                                        <div className="align-self-center">
                                                                            <div className="p-1 text-left"><img className="icon-aff"
                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                alt="" /> LV 3 <div><b className="m-0 font-member-aff">0</b><small className="text_aff_affiliate">
                                                                                    คน</small></div><small className="text_aff_affiliate">แนะนำสมาชิกทั้งหมด</small></div>
                                                                        </div>
                                                                        <div className="align-self-center text-right">
                                                                            <div>
                                                                                <div><small className="font-play-text text_aff_affiliate">จากเดือนที่แล้ว</small></div><span
                                                                                    className="badge text-bg-dark">0.00%</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 p-1">
                                                                <div className="elementor-element-play-overview p-2">
                                                                    <div className="d-flex justify-content-between text-center">
                                                                        <div className="align-self-center">
                                                                            <div className="p-1 text-left"><img className="icon-aff"
                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                alt="" /> LV 4 <div><b className="m-0 font-member-aff">0</b><small className="text_aff_affiliate">
                                                                                    คน</small></div><small className="text_aff_affiliate">แนะนำสมาชิกทั้งหมด</small></div>
                                                                        </div>
                                                                        <div className="align-self-center text-right">
                                                                            <div>
                                                                                <div><small className="font-play-text text_aff_affiliate">จากเดือนที่แล้ว</small></div><span
                                                                                    className="badge text-bg-dark">0.00%</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 p-1">
                                                                <div className="elementor-element-play-overview p-2">
                                                                    <div className="d-flex justify-content-between text-center">
                                                                        <div className="align-self-center">
                                                                            <div className="p-1 text-left"><img className="icon-aff"
                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                alt="" /> LV 5 <div><b className="m-0 font-member-aff">0</b><small className="text_aff_affiliate">
                                                                                    คน</small></div><small className="text_aff_affiliate">แนะนำสมาชิกทั้งหมด</small></div>
                                                                        </div>
                                                                        <div className="align-self-center text-right">
                                                                            <div>
                                                                                <div><small className="font-play-text text_aff_affiliate">จากเดือนที่แล้ว</small></div><span
                                                                                    className="badge text-bg-dark">0.00%</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        <div className="bg-profile-box-overview p-2 mb-2 mt-2">
                                                            <div className="d-flex justify-content-between row m-0 align-items-center">
                                                                <div className="col-12 col-md-12 p-0">
                                                                    <div
                                                                        className="d-flex justify-content-between elementor-element-play-overview text-center bg-profile-credit-bl p-2">
                                                                        <div className="align-self-center">
                                                                            <div className="p-1 text-left"><small className="text_aff_affiliate">ยอดการเล่นทุกประเภท</small>
                                                                                <div><label className="m-0"><b className="p-1">0 คน</b></label></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <table className="table">
                                                                <tbody>
                                                                    <tr className="text-center cl-mod-1">
                                                                        <td className="p-1-aff" style={{ width: '50px' }}><img className="icon-aff-member"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                            alt="" /></td>
                                                                        <td className="text-left p-1-aff text_aff_affiliate"><b>ลำดับที่ 1</b></td>
                                                                        <td className="text-left p-1-aff text_aff_affiliate">0</td>
                                                                        <td className="p-1-aff text_aff_affiliate">คน</td>
                                                                    </tr>
                                                                    <tr className="text-center cl-mod-2">
                                                                        <td className="p-1-aff" style={{ width: '50px' }}><img className="icon-aff-member"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                            alt="" /></td>
                                                                        <td className="text-left p-1-aff text_aff_affiliate"><b>ลำดับที่ 2</b></td>
                                                                        <td className="text-left p-1-aff text_aff_affiliate">0</td>
                                                                        <td className="p-1-aff text_aff_affiliate">คน</td>
                                                                    </tr>
                                                                    <tr className="text-center cl-mod-1">
                                                                        <td className="p-1-aff" style={{ width: '50px' }}><img className="icon-aff-member"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                            alt="" /></td>
                                                                        <td className="text-left p-1-aff text_aff_affiliate"><b>ลำดับที่ 3</b></td>
                                                                        <td className="text-left p-1-aff text_aff_affiliate">0</td>
                                                                        <td className="p-1-aff text_aff_affiliate">คน</td>
                                                                    </tr>
                                                                    <tr className="text-center cl-mod-2">
                                                                        <td className="p-1-aff" style={{ width: '50px' }}><img className="icon-aff-member"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                            alt="" /></td>
                                                                        <td className="text-left p-1-aff text_aff_affiliate"><b>ลำดับที่ 4</b></td>
                                                                        <td className="text-left p-1-aff text_aff_affiliate">0</td>
                                                                        <td className="p-1-aff text_aff_affiliate">คน</td>
                                                                    </tr>
                                                                    <tr className="text-center cl-mod-1">
                                                                        <td className="p-1-aff" style={{ width: '50px' }}><img className="icon-aff-member"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                            alt="" /></td>
                                                                        <td className="text-left p-1-aff text_aff_affiliate"><b>ลำดับที่ 5</b></td>
                                                                        <td className="text-left p-1-aff text_aff_affiliate">0</td>
                                                                        <td className="p-1-aff text_aff_affiliate">คน</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table> */}
                                                        </div>
                                                        <div className="d-flex justify-content-between pt-2 pb-2">
                                                            <div role="button" className="align-self-end"><small><b>&nbsp;รายละเอียกเครือข่าย</b></small></div>
                                                        </div>
                                                        <div className="bg-profile-box-overview p-2 mb-2">
                                                            <table className="table">
                                                                <thead className="table-dark-history">
                                                                    <tr className="bg-table-dark-history-title">
                                                                        <td className="credit-bl-text text_aff_affiliate"><small>วันที่</small></td>
                                                                        <td className="credit-bl-text text_aff_affiliate"><small>ยอดถอน</small></td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="bg-table-dark-history">
                                                                        <td colSpan={2} className="text-white text-center">
                                                                            <div>
                                                                                <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp"><b><img
                                                                                    className="load-history"
                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                                    alt="" />
                                                                                    <div className="not-history font-history-menu-text p-2"><small><b>ไม่พบข้อมูล</b></small></div>
                                                                                </b></div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-2 bg-profile-box-overview" style={{ display: this.state.tabselect == 4 && this.state.refer == 3 ? "" : "none" }}>
                                            <div className="animated animatedFadeInUp fadeInUp">
                                                <div className="align-self-center">
                                                    <div className="p-1 text-left"><label>สรุปรายได้ทั้งหมด</label></div>
                                                </div>
                                                <div className="bg-profile-box-overview p-2 mb-2">
                                                    <div className="d-flex justify-content-between row m-0 align-items-center">
                                                        <div className="col-12 col-md-12 p-0">
                                                            <div
                                                                className="d-flex justify-content-between elementor-element-play-overview text-center bg-profile-credit-bl p-2">
                                                                <div className="align-self-center">
                                                                    <div className="p-1 text-left">
                                                                        <div><small className="text_aff_affiliate">รายได้จากเครือข่าย ทั้งหมด</small>
                                                                            <h3 className="m-0"><b className="p-1">0.00</b></h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="align-self-center text-right">
                                                                    <div><span className="badge text-bg-dark">0.00%</span></div>
                                                                    <div><span className="font-play-text text_aff_affiliate">จากเดือนที่แล้ว</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt-2">
                                                        <div className="row m-0">
                                                            <div className="col-12 col-md-12 p-1">
                                                                <div className="d-flex justify-content-between financial-button">
                                                                    <div role="button" className="align-self-center p-1 credit-bl-text">
                                                                        <div role="button" className="financial-button-r">
                                                                            <div className="d-flex justify-content-start">
                                                                                <div className="align-self-center"><img className="logo-profile-user-w p-1"
                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/48a22d9a-8abe-477f-0f40-57204ce17700/public"
                                                                                    alt="" /></div>
                                                                                <div className="align-self-center">
                                                                                    <div className="username-profile"><small>รายได้ที่ถอนได้ </small></div>
                                                                                    <div className="username-profile"><small><b className="font-aff-wid">0.00</b></small><a
                                                                                        role="button" className="text-white-credit-balance p-1" id="icon-refresh-credit"><span><b><svg
                                                                                            xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="currentColor"
                                                                                            className="text-white bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                                                            <path fillRule="evenodd"
                                                                                                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                                                                            <path
                                                                                                d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                                                                        </svg></b></span></a></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bg-profile-box-overview p-2 mb-2">
                                                    <div className="d-flex justify-content-between row m-0 align-items-center">
                                                        <div className="col-12 col-md-12 p-0">
                                                            <div
                                                                className="d-flex justify-content-between elementor-element-play-overview text-center bg-profile-credit-bl">
                                                                <div className="align-self-center">
                                                                    <div className="p-1 text-left">
                                                                        <div><img className="icon-aff-member"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/db83e83e-f021-468d-995c-c10ebf09fb00/public"
                                                                            alt="" /><small className="text_aff_affiliate"> ค้นหาที่มารายได้</small></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row text-center m-0">
                                                        <div className="col-12 col-md-6 p-1"><select className="form-control option-search-aff"
                                                            style={{ textAlign: 'center' }}>
                                                            <option value="all">ทุกเลเวล</option>
                                                            <option value="lv1">level 1</option>
                                                            <option value="lv2">level 2</option>
                                                            <option value="lv3">level 3</option>
                                                            <option value="lv5">level 5</option>
                                                        </select></div>
                                                        <div className="col-12 col-md-6 p-1"><select className="form-control option-search-aff"
                                                            style={{ textAlign: 'center' }}>
                                                            <option value="all">ทุกประเภท</option>
                                                            <option value="slot">สล็อต</option>
                                                            <option value="casino">คาสิโน</option>
                                                            <option value="winloss">ยอดเสีย</option>
                                                        </select></div>
                                                        <div className="col-12 col-md-12 p-1 text-left">
                                                            <div className="input-group"><input type="text"
                                                                className="form-control bg-income-summary text-center-s-date font-play-text" placeholder="วันนี้ — วันนี้"
                                                                aria-label="วันนี้ — วันนี้" disabled aria-describedby="basic-addon2" /><span role="button"
                                                                    className="input-group-text" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width={16}
                                                                        height={16} fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                                                                        <path
                                                                            d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                                                    </svg></span></div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row text-center">
                                                                <div className="col-12 col-md-6 p-1">
                                                                    <div role="button" className="affiliate-aff-search p-2"><label className="font-play-text">ล้างค่า</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 p-1">
                                                                    <div role="button" className="affiliate-aff-search-submit p-2"><label>ค้นหา</label></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="credit-bl-text pb-2"><span><small>คุณกำลังดูช่วงเวลา &nbsp; </small></span></div>
                                                <div className="bg-profile-box-overview p-2 mb-2">
                                                    <div className="d-flex justify-content-between pb-2">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                            <div className="p-2"><img className="icon-aff-member"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public" alt="" />
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <h5 className="credit-bl-text"><b>ระดับที่ 1</b></h5>
                                                                </div>
                                                                <div><small className="text_aff_affiliate">รายละเอียดการทำรายได้</small></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table className="table mb-0">
                                                        <thead className="table-dark-history">
                                                            <tr className="bg-table-dark-history-title">
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>ประเภท</small></td>
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>ยอดแทง</small></td>
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>รายได้</small></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="bg-table-dark-history">
                                                                <td colSpan={3} className="text-white text-center">
                                                                    <div>
                                                                        <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp"><b><img
                                                                            className="load-history"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                            alt="" />
                                                                            <div className="not-history font-history-menu-text p-2"><small><b>ไม่พบข้อมูล</b></small></div>
                                                                        </b></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* <div className="bg-profile-box-overview p-2 mb-2">
                                                    <div className="d-flex justify-content-between pb-2">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                            <div className="p-2"><img className="icon-aff-member"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public" alt="" />
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <h5 className="credit-bl-text"><b>ระดับที่ 2</b></h5>
                                                                </div>
                                                                <div><small className="text_aff_affiliate">รายละเอียดการทำรายได้</small></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table className="table mb-0">
                                                        <thead className="table-dark-history">
                                                            <tr className="bg-table-dark-history-title">
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>ประเภท</small></td>
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>ยอดแทง</small></td>
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>รายได้</small></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="bg-table-dark-history">
                                                                <td colSpan={3} className="text-white text-center">
                                                                    <div>
                                                                        <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp"><b><img
                                                                            className="load-history"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                            alt="" />
                                                                            <div className="not-history font-history-menu-text p-2"><small><b>ไม่พบข้อมูล</b></small></div>
                                                                        </b></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="bg-profile-box-overview p-2 mb-2">
                                                    <div className="d-flex justify-content-between pb-2">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                            <div className="p-2"><img className="icon-aff-member"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public" alt="" />
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <h5 className="credit-bl-text"><b>ระดับที่ 3</b></h5>
                                                                </div>
                                                                <div><small className="text_aff_affiliate">รายละเอียดการทำรายได้</small></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table className="table mb-0">
                                                        <thead className="table-dark-history">
                                                            <tr className="bg-table-dark-history-title">
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>ประเภท</small></td>
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>ยอดแทง</small></td>
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>รายได้</small></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="bg-table-dark-history">
                                                                <td colSpan={3} className="text-white text-center">
                                                                    <div>
                                                                        <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp"><b><img
                                                                            className="load-history"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                            alt="" />
                                                                            <div className="not-history font-history-menu-text p-2"><small><b>ไม่พบข้อมูล</b></small></div>
                                                                        </b></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="bg-profile-box-overview p-2 mb-2">
                                                    <div className="d-flex justify-content-between pb-2">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                            <div className="p-2"><img className="icon-aff-member"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public" alt="" />
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <h5 className="credit-bl-text"><b>ระดับที่ 4</b></h5>
                                                                </div>
                                                                <div><small className="text_aff_affiliate">รายละเอียดการทำรายได้</small></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table className="table mb-0">
                                                        <thead className="table-dark-history">
                                                            <tr className="bg-table-dark-history-title">
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>ประเภท</small></td>
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>ยอดแทง</small></td>
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>รายได้</small></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="bg-table-dark-history">
                                                                <td colSpan={3} className="text-white text-center">
                                                                    <div>
                                                                        <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp"><b><img
                                                                            className="load-history"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                            alt="" />
                                                                            <div className="not-history font-history-menu-text p-2"><small><b>ไม่พบข้อมูล</b></small></div>
                                                                        </b></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="bg-profile-box-overview p-2 mb-2">
                                                    <div className="d-flex justify-content-between pb-2">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                            <div className="p-2"><img className="icon-aff-member"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public" alt="" />
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <h5 className="credit-bl-text"><b>ระดับที่ 5</b></h5>
                                                                </div>
                                                                <div><small className="text_aff_affiliate">รายละเอียดการทำรายได้</small></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table className="table mb-0">
                                                        <thead className="table-dark-history">
                                                            <tr className="bg-table-dark-history-title">
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>ประเภท</small></td>
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>ยอดแทง</small></td>
                                                                <td className="credit-bl-text text_aff_affiliate text-center"><small>รายได้</small></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="bg-table-dark-history">
                                                                <td colSpan={3} className="text-white text-center">
                                                                    <div>
                                                                        <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp"><b><img
                                                                            className="load-history"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                            alt="" />
                                                                            <div className="not-history font-history-menu-text p-2"><small><b>ไม่พบข้อมูล</b></small></div>
                                                                        </b></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="p-2 bg-profile-box-overview" style={{ display: this.state.tabselect == 4 && this.state.refer == 4 ? "" : "none" }}>

                                            <div className="animated animatedFadeInUp fadeInUp text-center">
                                                <div className="bg-profile-box p-2">
                                                    <div>
                                                        <div className="p-2 align-self-center">
                                                            <div className="username-profile"><b>รวยง่ายๆ กับ Secret789</b></div>
                                                            <div className="username-profile"><b>แค่แชร์ลิงก์ก็สร้างรายได้</b></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp"><b><img
                                                                className="load-history"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                alt="" />
                                                                <div className="not-history font-history-menu-text p-2"><small><b>ไม่พบข้อมูล</b></small></div>
                                                            </b></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="container p-0 mt-1" style={{ display: this.state.tabselect == 5 ? "" : "none" }}>
                                    <div className="m-2 border-shadow-title text-color">
                                        <div
                                            data-v-ab1d19ba=""
                                            className="auction top-loss animated animatedFadeInUp fadeInUp"
                                            role="button"
                                        >
                                            <div
                                                data-v-ab1d19ba=""
                                                className="auction__body-menu auction__body--border-radius"
                                            >
                                                <div data-v-ab1d19ba="" className="tab text-color">
                                                    <h3 className="x-title-modal mx-auto text-center">
                                                        <b>คืนยอดเดิมพัน</b>
                                                    </h3>
                                                    <hr />
                                                    {/**/}
                                                    {/**/}
                                                    <div className="d-flex justify-content-between text-center border-radius-com-head">
                                                        <div className="p-2 text-center">
                                                            <b>คืนยอดเดิมพันสะสมทั้งหมด</b>
                                                        </div>
                                                        <div className="p-2">0</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between text-center border-radius-com-head mt-2 mb-2">
                                                        <div className="p-2 text-center">
                                                            <b>คืนยอดเดิมพันสะสมเดือนนี้</b>
                                                        </div>
                                                        <div className="p-2">0</div>
                                                    </div>
                                                    <div className="text-left"> คืนยอดเดิมพัน slot {cashoutSet.percent}% </div>
                                                    <div className="text-left"> คืนยอดเดิมพัน casino {cashoutSet.percent}% </div>
                                                    <div
                                                        data-v-00ef951c=""
                                                        className="auction-pool-menu-commission mt-2 mb-2"
                                                    >
                                                        <div className="p-4 text-center">
                                                            <label htmlFor="" className="">
                                                                <h5>คืนยอดเดิมพันของคุณ</h5>
                                                            </label>
                                                            <h5 className="text-light" style={{ fontWeight: "600" }}>{(tblprofile != null) ? tblprofile.Cashtotal : 0}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="text-color">ยอดเริ่มต้น {cashoutSet.maxwithdraw} บาท</div>
                                                    <div data-v-00ef951c=""
                                                        className="auction-pool-menu">
                                                        <div className="font-income">
                                                            <div className="mt-2 mb-2">
                                                                <button
                                                                    onClick={(e) => {
                                                                        this.opencliamcashout(e, 'bonusrankaccount')
                                                                    }}
                                                                    className="btn -submit js-submit-accept-term v-login mt-lg-1 mt-0 w-100 p-2">
                                                                    <span> เเจ้งถอนรายได้ </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-2 text-left mt-4 mb-1 text-color animated animatedFadeInUp fadeInUp">
                                            {" "}
                                            ประวัติการทำรายการ{" "}
                                        </div>
                                        <div className="animated animatedFadeInUp fadeInUp">
                                            <div>
                                                <div>
                                                    <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                        <b>
                                                            <img
                                                                className="load-history"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                alt=""
                                                            />
                                                            <div className="not-history font-history-menu-text p-2">
                                                                <small>
                                                                    <b>ไม่พบข้อมูล</b>
                                                                </small>
                                                            </div>
                                                        </b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container p-0 mt-1" style={{ display: this.state.tabselect == 6 ? "" : "none" }}>
                                    <div className="m-2 border-shadow-title">
                                        <div
                                            data-v-ab1d19ba=""
                                            className="auction top-loss animated animatedFadeInUp fadeInUp"
                                            role="button"
                                        >
                                            <div
                                                data-v-ab1d19ba=""
                                                className="auction__body-menu auction__body--border-radius"
                                            >
                                                <div data-v-ab1d19ba="" className="tab text-color">
                                                    <h3 className="x-title-modal mx-auto text-center">
                                                        <b>คืนยอดเสีย</b>
                                                    </h3>
                                                    <hr />
                                                    {/**/}
                                                    {/**/}
                                                    <div className="d-flex justify-content-between text-center border-radius-com-head">
                                                        <div className="p-2 text-center">
                                                            <b>คืนยอดเสียสะสมทั้งหมด</b>
                                                        </div>
                                                        <b className="p-1">{(tblprofile != null) ? tblprofile.Cashtotal : 0} </b>
                                                    </div>
                                                    <div className="d-flex justify-content-between text-center border-radius-com-head mt-2 mb-2">
                                                        <div className="p-2 text-center">
                                                            <b>คืนยอดเสียสะสมเดือนนี้</b>
                                                        </div>
                                                        <b className="p-1">{(tblprofile != null) ? tblprofile.Cashtotal : 0}</b>
                                                    </div>
                                                    <div className="text-left"> คืนยอดเสีย {(cashoutSet != null) ? cashoutSet.percent : 0}% </div>
                                                    <div
                                                        data-v-00ef951c=""
                                                        className="auction-pool-menu-commission mt-2 mb-2"
                                                    >
                                                        <div className="p-4 text-center">
                                                            <label htmlFor="" className="">
                                                                <h5>คืนยอดเสียของคุณ</h5>
                                                            </label>
                                                            <h5 className="text-danger">        <b className="font-aff-wid">{(tblprofile != null) ? tblprofile.Cashtotal : 0}</b></h5>
                                                        </div>
                                                    </div>
                                                    <div className="text-color">ยอดเริ่มต้น 1 บาท</div>
                                                    <div data-v-00ef951c="" className="auction-pool-menu">
                                                        <div className="font-income">
                                                            <div className="mt-2 mb-2">
                                                                <a
                                                                    onClick={(e) => {
                                                                        this.opencliamcashout(e, 'bonusrankaccount')
                                                                    }}
                                                                    className="btn -submit js-submit-accept-term v-login mt-lg-1 mt-0 w-100 p-2">
                                                                    <span> เเจ้งถอนรายได้ </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-2 text-left mt-4 mb-1 animated animatedFadeInUp fadeInUp text-color">
                                            {" "}
                                            ประวัติการทำรายการ{" "}
                                        </div>
                                        <div className="animated animatedFadeInUp fadeInUp">
                                            <div>
                                                <div>
                                                    <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                        <b>
                                                            <img
                                                                className="load-history"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                alt=""
                                                            />
                                                            <div className="not-history font-history-menu-text p-2">
                                                                <small>
                                                                    <b>ไม่พบข้อมูล</b>
                                                                </small>
                                                            </div>
                                                        </b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container p-0 mt-2" style={{ display: this.state.tabselect == 7 ? "" : "none" }}>
                                    <div className="m-2 border-shadow-title animated animatedFadeInUp fadeInUp">
                                        <div
                                            data-v-ab1d19ba=""
                                            className="auction top-loss animated animatedFadeInUp fadeInUp"
                                            role="button"
                                        >
                                            <div
                                                data-v-ab1d19ba=""
                                                className="auction__body-menu auction__body--border-radius"
                                            >
                                                <div data-v-ab1d19ba="" className="tab text-color">
                                                    <h3 className="x-title-modal mx-auto text-center">
                                                        <b>คูปอง</b>
                                                    </h3>
                                                    <hr />
                                                    <div className="mb-1">
                                                        <label htmlFor="price" className="mt-1 mb-1 text-left">
                                                            กรอกโค้ดโปรโมชั่น
                                                        </label>
                                                        <div className="-x-input-icon flex-column">
                                                            <div className="input-group mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="bg-border-input form-control form-control-form form-not-input-login-register"
                                                                    placeholder=""
                                                                    aria-label={0}
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 mb-2">
                                                        {/**/}
                                                        <div>
                                                            <div>
                                                                <button
                                                                    onClick={(e) => {
                                                                        this.couponredeem(e)
                                                                    }}
                                                                    type="submit"
                                                                    className="btn -submit js-submit-accept-term v-login mt-0 w-100 p-2 mb-4"
                                                                >
                                                                    ยืนยัน
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 mb-4">
                                                        <label htmlFor="price" className="mb-1 text-left">
                                                            โปรโมชั่นทั้งหมด
                                                        </label>
                                                        <div className="text-left">
                                                            <small>
                                                                <b>หมายเหตุ :</b>: "
                                                                เพียงฝากให้ยอดตรงกับเงื่อนไขโปรโมชั่นท่านจะได้รับโปรโมชั่นอัตโนมัติทันที{" "}
                                                                {/**/}"{" "}
                                                            </small>
                                                        </div>
                                                        <div className="p-1">
                                                            <div className="row">
                                                                {prolistx.map(prolists => (
                                                                    <div className="text-center mb-4 col-12 col-lg-4">
                                                                        <a
                                                                            href="#0"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={(e) => this.checkpro(e, prolists.no)}
                                                                        >
                                                                            <div
                                                                                className="p-1 bg-mission-button example"
                                                                            >
                                                                                <img
                                                                                    className="w-100"
                                                                                    data-action="zoom"
                                                                                    src={prolists.linkpicture}
                                                                                    alt={prolists.linkpicture}
                                                                                    style={{ borderRadius: 13 }}
                                                                                />
                                                                                <div className="text position-auto-text">
                                                                                    {prolists.proname}
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="x-margin-top-v3 pt-3 m-2" style={{ display: this.state.tabselect == 8 ? "" : "none" }}>
                                    <div className="animated animatedFadeInUp fadeInUp">
                                        <div className="js-profile-account-modal -layout-account">
                                            <div className="x-account-profile -v2">
                                                <div
                                                    data-animatable="fadeInModal"
                                                    className="-profile-container animated fadeInModal"
                                                >
                                                    <h3 className="x-title-modal mx-auto text-center">
                                                        <b>ข้อมูลบัญชี</b>
                                                    </h3>
                                                    <div className="animated animatedFadeInUp fadeInUp p-2" style={{ display: this.state.tabselect == 8 && this.state.resetpass == 0 ? "" : "none" }}>
                                                        <div className="text-center">
                                                            <div className="my-3">
                                                                <div className="x-profile-image">
                                                                    <img
                                                                        className="img-fluid -profile-image"
                                                                        src={"/build/rank/" + this.state.rankpic}
                                                                        // src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                                                                        alt="customer image"
                                                                    />{" "}
                                                                    {username}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="collapse -change-password-container js-change-password-collapse"
                                                            >
                                                                <div className="js-collapse-content" />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="text-white text-center col mx-auto my-auto">
                                                                <div className="box-content p-1 p-sm-1">
                                                                    <div className="flex justify-between mb-1 mx-auto">
                                                                        <div className="sub-content w-100 p-3">
                                                                            <div className="text-center">
                                                                                <img
                                                                                    className="rounded-full w-10 mb-2 flex"
                                                                                    src={accnumber !== null && accnumber !== undefined ? accnumberx.img_path : ""}
                                                                                    style={{ width: 38 }}
                                                                                />{" "}
                                                                                &nbsp; {accnumber !== null && accnumber !== undefined ? accnumberx.fullname_th : ""}
                                                                            </div>
                                                                            <div className="text-center">
                                                                                {firstname} &nbsp; | &nbsp;
                                                                                {accnumber !== null && accnumber !== undefined ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="x-admin-contact text-center">
                                                            <a
                                                                onClick={(e) => this.setState({
                                                                    resetpass: 1,
                                                                })
                                                                }
                                                                role="button"
                                                                className="-link-change-password"
                                                                data-toggle="collapse"
                                                                data-target=".js-change-password-collapse"
                                                            >
                                                                <u>เปลี่ยนรหัสผ่าน</u>
                                                            </a>
                                                            <br />
                                                            <span className="x-text-with-link-component">
                                                                <label className="-text-message">พบปัญหา</label>
                                                                <a
                                                                    href={this.state.linelink}
                                                                    role="button"
                                                                    className="-link-message"
                                                                    target="_blank"
                                                                    rel="noopener"
                                                                >
                                                                    <u>ติดต่อฝ่ายบริการลูกค้า</u>
                                                                </a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="text-right animated animatedFadeInUp fadeInUp p-2" style={{ display: this.state.tabselect == 8 && this.state.resetpass == 1 ? "" : "none" }}>
                                                        <div className="x-login-index animated animatedFadeInUp fadeInUp p-2">
                                                            <div className="container p-0">
                                                                <div className="animated animatedFadeInUp fadeInUp mt-2 mb-5-profile text-white" data-v-c2788d34>
                                                                    <div className="row m-0">
                                                                        <div onClick={(e) => this.setState({
                                                                            resetpass: 0,
                                                                        })
                                                                        } className="col-6 text-left" style={{ textAlign: 'left' }}><small className="mt-4 mb-2" role="button"
                                                                            data-v-c2788d34><b data-v-c2788d34><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20}
                                                                                fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                                                                <path fillRule="evenodd"
                                                                                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                                                            </svg> กลับ </b></small></div>
                                                                        <div className="col-6" style={{ textAlign: 'right' }}><small className="mt-4 mb-2" data-v-c2788d34><b
                                                                            data-v-c2788d34> เปลี่ยนรหัสผ่าน </b></small></div>
                                                                        <div className="col-12">
                                                                            <div className="login-modal text-left" style={{ textAlign: 'left' }}>
                                                                                <div className="mx-0 lg:mx-5 text-white">
                                                                                    <div className="label mt-2"><label htmlFor="password">รหัสผ่านเดิม</label></div>
                                                                                    <div className="input-group mb-3" data-v-8cd0ec04><span
                                                                                        className="input-group-text input-group-circle-left" data-v-8cd0ec04><svg
                                                                                            xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor"
                                                                                            className="bi bi-key" viewBox="0 0 16 16">
                                                                                            <path
                                                                                                d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                                                                                            <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                                        </svg></span>
                                                                                        <input onChange={this.onChangex}
                                                                                            id="oldpassword"
                                                                                            name="oldpassword"
                                                                                            type={this.state.showpassold ? "text" : "password"}
                                                                                            required="required"
                                                                                            placeholder="กรุณารหัสผ่าน"
                                                                                            autoFocus="autoFocus"
                                                                                            autoComplete="on"
                                                                                            inputMode="text"
                                                                                            className="form-control input-group-text"
                                                                                            aria-label="รหัสผ่าน" aria-describedby="basic-addon1" /><span

                                                                                                onClick={(e) => {
                                                                                                    this.onChangexxxxx(1)
                                                                                                }}
                                                                                                className="input-group-text input-group-circle-right" id="change-eye" role="button"><svg
                                                                                                    xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor"
                                                                                                    className="bi bi-eye-slash" viewBox="0 0 16 16">
                                                                                                <path
                                                                                                    d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                                                                <path
                                                                                                    d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                                                                <path
                                                                                                    d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                                                                            </svg></span></div>
                                                                                    <div className="label mt-6"><label htmlFor="password">รหัสผ่านใหม่</label></div>
                                                                                    <div className="input-group mb-3" data-v-8cd0ec04><span

                                                                                        className="input-group-text input-group-circle-left" data-v-8cd0ec04><svg
                                                                                            xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor"
                                                                                            className="bi bi-shield-lock-fill" viewBox="0 0 16 16">
                                                                                            <path fillRule="evenodd"
                                                                                                d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm0 5a1.5 1.5 0 0 1 .5 2.915l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99A1.5 1.5 0 0 1 8 5z" />
                                                                                        </svg></span><input onChange={this.onChangex}
                                                                                            type={this.state.showpassnew ? "text" : "password"}
                                                                                            id="password"
                                                                                            name="password"
                                                                                            required="required"
                                                                                            placeholder="กรุณารหัสผ่าน"
                                                                                            autoFocus="autoFocus"
                                                                                            autoComplete="on"
                                                                                            inputMode="text"
                                                                                            className="form-control input-group-text"
                                                                                            aria-label="รหัสผ่านใหม่" aria-describedby="basic-addon1" /><span
                                                                                                onClick={(e) => {
                                                                                                    this.onChangexxxxx(2)
                                                                                                }}
                                                                                                className="input-group-text input-group-circle-right" id="change-eye" role="button"><svg
                                                                                                    xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor"
                                                                                                    className="bi bi-eye-slash" viewBox="0 0 16 16">
                                                                                                <path
                                                                                                    d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                                                                <path
                                                                                                    d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                                                                <path
                                                                                                    d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                                                                            </svg></span></div>
                                                                                    <div className="label mt-6"><label htmlFor="password">ยืนยันรหัสผ่าน</label></div>
                                                                                    <div
                                                                                        className="input-group mb-3" data-v-8cd0ec04><span

                                                                                            className="input-group-text input-group-circle-left" data-v-8cd0ec04><svg
                                                                                                xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor"
                                                                                                className="bi bi-shield-lock-fill" viewBox="0 0 16 16">
                                                                                                <path fillRule="evenodd"
                                                                                                    d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm0 5a1.5 1.5 0 0 1 .5 2.915l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99A1.5 1.5 0 0 1 8 5z" />
                                                                                            </svg></span><input
                                                                                            onChange={this.onChangex}
                                                                                            type={this.state.showpasscf ? "text" : "password"}
                                                                                            id="passwordcf"
                                                                                            name="passwordcf"
                                                                                            required="required"
                                                                                            placeholder="กรุณารหัสผ่าน"
                                                                                            autoFocus="autoFocus"
                                                                                            autoComplete="on"
                                                                                            inputMode="text"
                                                                                            aria-label="รหัสผ่าน"
                                                                                            className="form-control input-group-text"
                                                                                            aria-describedby="basic-addon1" /><span
                                                                                                onClick={(e) => {
                                                                                                    this.onChangexxxxx(3)
                                                                                                }}
                                                                                                className="input-group-text input-group-circle-right" id="change-eye" role="button"><svg
                                                                                                    xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor"
                                                                                                    className="bi bi-eye-slash" viewBox="0 0 16 16">
                                                                                                <path
                                                                                                    d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                                                                <path
                                                                                                    d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                                                                <path
                                                                                                    d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                                                                            </svg></span></div><button
                                                                                                onClick={(e) => {
                                                                                                    this.resetpassword(e, 1)
                                                                                                }}


                                                                                                className="btn box-shadow-danger js-submit-accept-term v-login mt-lg-1 mt-0 w-100 p-3"><span>ยืนยัน
                                                                                        </span></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="x-margin-top-v3 pt-3 m-2" style={{ display: this.state.tabselect == 10 ? "" : "none" }}>
                                    <Casino props={this.state} />
                                </div>
                                <div className="x-margin-top-v3 pt-3 m-2" style={{ display: this.state.tabselect == 11 ? "" : "none" }}>
                                    <GameHit props={this.state} />
                                </div>
                                <div className="x-margin-top-v3 pt-3 m-2" style={{ display: this.state.tabselect == 12 ? "" : "none" }}>
                                    <Fishing props={this.state} />
                                </div>
                                <div className="x-margin-top-v3 pt-3 m-2" style={{ display: this.state.tabselect == 13 ? "" : "none" }}>
                                    <Slot props={this.state} />
                                </div>
                                <div className="x-margin-top-v3 pt-3 m-2" style={{ display: this.state.tabselect == 14 ? "" : "none" }}>
                                    <Sport props={this.state} />
                                </div>
                                <div className="x-margin-top-v3 pt-3 m-2" style={{ display: this.state.tabselect == 15 ? "" : "none" }}>
                                    <Listgameall props={this.state} />
                                </div>
                                <div className="x-margin-top-v3 pt-3 m-2" style={{ display: this.state.tabselect == 17 ? "" : "none" }}>
                                    <Lotto props={this.state} />
                                </div>


                                <div className="container p-0 mt-3" style={{ display: this.state.tabselect == 16 ? "" : "none" }}>
                                    <div className="m-0 border-shadow-title animated animatedFadeInUp fadeInUp">
                                        <div
                                            data-v-ab1d19ba=""
                                            className="auction top-loss animated animatedFadeInUp fadeInUp"
                                            role="button"
                                        >
                                            <div
                                                data-v-ab1d19ba=""
                                                className="auction__body-menu auction__body--border-radius"
                                            >
                                                <div data-v-ab1d19ba="" className="tab text-color">
                                                    <div className="row m-0">
                                                        <div className="col-4 text-center p-1">
                                                            <div className="p-1 bg-mission-button example">
                                                                <a onClick={(e) => {
                                                                    this.checktab(e, 4)
                                                                }}
                                                                >
                                                                    <img
                                                                        className="w-100 img-hover-event"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5b484701-23cf-4a1e-a37b-251dd801a000/public"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 text-center p-1">
                                                            <div className="p-1 bg-mission-button example">
                                                                <a onClick={(e) => {
                                                                    this.checktab(e, 5)
                                                                }}
                                                                >
                                                                    <img
                                                                        className="w-100 img-hover-event"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d19594c3-f5b7-4544-ce8c-b923b8f13200/public"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 text-center p-1">
                                                            <div className="p-1 bg-mission-button example">
                                                                <a onClick={(e) => {
                                                                    this.checktab(e, 7)
                                                                }}
                                                                >
                                                                    <img
                                                                        className="w-100 img-hover-event"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/907ff1f1-3e6d-4cfc-c89b-1cd216488900/public"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-4 text-center p-1">
                                                            <div className="p-1 bg-mission-button example">
                                                                <a onClick={(e) => {
                                                                    this.checktab(e, 24)
                                                                }}
                                                                >
                                                                    <img
                                                                        className="w-100 img-hover-event"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/bf52d088-0047-40c3-fe9b-982dd7236700/public"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-4 text-center p-1">
                                                            <div className="p-1 bg-mission-button example">
                                                                <a onClick={(e) => {
                                                                    this.checktab(e, 25)
                                                                }}
                                                                >
                                                                    <img
                                                                        className="w-100 img-hover-event"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/26f16614-9ab8-4ea3-9613-b5ff90f3d300/public"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-4 text-center p-1">
                                                            <div className="p-1 bg-mission-button example">
                                                                <a onClick={(e) => {
                                                                    this.checktab(e, 26)
                                                                }}
                                                                >
                                                                    <img
                                                                        className="w-100 img-hover-event"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d38de628-cbb9-457e-06fe-0ff023a21900/public"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div> */}
                                                        <div className="col-4 text-center p-1">
                                                            <div className="p-1 bg-mission-button example">
                                                                <a onClick={(e) => {
                                                                    this.checktab(e, 5)
                                                                }}
                                                                >
                                                                    <img
                                                                        className="w-100 img-hover-event"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/c62a09a8-29aa-4e8d-b6dd-438e1de04500/public"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-3" style={{ display: this.state.tabselect == 20 ? "" : "none" }}>
                                    <div className="x-login-index animated animatedFadeInUp fadeInUp p-2">
                                        <div>
                                            <div>
                                                <div className="-inner-wrapper text-left">
                                                    <div className="bg-game-list mt-2 text-white">
                                                        <div className="d-flex justify-content-between p-2">
                                                            <div>
                                                                <a onClick={(e) => {
                                                                    this.checktab(e, 1)
                                                                }}>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={16}
                                                                        height={16}
                                                                        fill="currentColor"
                                                                        className="bi bi-arrow-left-circle"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                            <div />
                                                            <div>
                                                                <small>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={20}
                                                                        height={20}
                                                                        fill="currentColor"
                                                                        className="bi bi-controller"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1v-1z" />
                                                                        <path d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729c.14.09.266.19.373.297.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466.206.875.34 1.78.364 2.606.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527-1.627 0-2.496.723-3.224 1.527-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.34 2.34 0 0 1 .433-.335.504.504 0 0 1-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a13.748 13.748 0 0 0-.748 2.295 12.351 12.351 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.354 12.354 0 0 0-.339-2.406 13.753 13.753 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27-1.036 0-2.063.091-2.913.27z" />
                                                                    </svg>
                                                                    <label htmlFor="">&nbsp;ประเภท&nbsp;: ธนาคาร</label>
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mt-3">
                                                    <div className="x-login-index animated animatedFadeInUp fadeInUp p-2 text-white">
                                                        <div>
                                                            <h6 className="mt-1 text-center text-white">
                                                                <u>บัญชีของคุณ</u>
                                                            </h6>
                                                            <div>
                                                                <div>
                                                                    <div className="box-content p-1 p-sm-1">
                                                                        <div className="flex justify-between mb-1 mx-auto">
                                                                            <div className="sub-content w-100 p-3">
                                                                                <div className="text-center">
                                                                                    <img
                                                                                        className="rounded-full w-10 mb-2 flex"
                                                                                        src={accnumber !== null && accnumber !== undefined ? accnumberx.img_path : ""}
                                                                                        style={{ width: 38 }}
                                                                                    />{" "}
                                                                                    &nbsp; {accnumber !== null && accnumber !== undefined ? accnumberx.fullname_th : ""}
                                                                                </div>
                                                                                <div className="text-center">
                                                                                    {firstname} &nbsp; | &nbsp;
                                                                                    {accnumber !== null && accnumber !== undefined ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="text-white text-center">
                                                                    <h6 className="mb-2 mt-2 text-center">
                                                                        <u>โอนเข้าบัญชี</u>
                                                                    </h6>
                                                                    <div>
                                                                        {mainacc !== null && mainacc !== undefined && mainacc !== '' ?
                                                                            listacc.map((listitem, i) => (
                                                                                <div className="flex justify-between mb-1 mx-auto box-content">
                                                                                    <div className="sub-content w-100 p-3 px-lg-5">
                                                                                        <div className="text-center">
                                                                                            <img
                                                                                                className="rounded-full w-10 mb-2 flex"
                                                                                                src={listitem.img_path}
                                                                                                style={{ width: 38 }}
                                                                                            />{" "}
                                                                                            &nbsp;{listitem.fullname_th}
                                                                                        </div>
                                                                                        <div className="text-center">
                                                                                            {listitem.first_name_en + " " + listitem.last_name_en} :&nbsp; {
                                                                                                listitem.accnumber.substring(0, 3) + "-" + listitem.accnumber.substring(3, 9) + "-" + listitem.accnumber.substring(9, 12)
                                                                                            }
                                                                                        </div>
                                                                                        <div className="text-center pt-2">
                                                                                            <div
                                                                                                className="btn v-login"
                                                                                                style={{ fontSize: 16, cursor: "pointer" }}
                                                                                            >
                                                                                                {" "}
                                                                                                เลขบัญชี :&nbsp;{" "}
                                                                                                <span id="copy-banktransfer">
                                                                                                    {listitem.accnumber}
                                                                                                </span>{" "}
                                                                                                &nbsp;&nbsp;
                                                                                                <a onClick={(e) => this.copy(e, listitem.accnumber)}>
                                                                                                    <span className="badge text-bg-white p-1" style={{ borderRadius: "33.9916px" }}  >
                                                                                                        &nbsp; คัดลอก &nbsp;
                                                                                                    </span>
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                            : ""}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="text-white text-center">
                                                                    <div className="box-content p-0">
                                                                        <div className="sub-content p-3">
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="text-white pt-1">
                                                                                    <img
                                                                                        src="/assets/ic-header-menu-promotion.d5c30a79.png"
                                                                                        alt=""
                                                                                        style={{ width: 30 }}
                                                                                    />{" "}
                                                                                    &nbsp; รับโปรโมชั่นอัตโนมัติ{" "}
                                                                                </div>
                                                                                <div className="text-white">
                                                                                    <div className="form-check form-switch">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            role="switch"
                                                                                            id="flexSwitchCheckDefault"
                                                                                            style={{ width: "6em", padding: 12 }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="popup-promotion"
                                        tabIndex={-1}
                                        aria-labelledby="popup-promotion-Label"
                                        aria-hidden="true"
                                        style={{ padding: 20 }}
                                    >
                                        <div className="modal-dialog modal-dialog-centered text-white">
                                            <div
                                                className="modal-content bg-popup-promotion"
                                                style={{ borderRadius: "15px !important" }}
                                            >
                                                <div className="mt-3">
                                                    <h1
                                                        className="modal-title fs-5 text-center"
                                                        id="popup-promotion-Label"
                                                    >
                                                        {" "}
                                                        รับโปรโมชั่นอัตโนมัติ{" "}
                                                    </h1>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="text-center row">
                                                        <div className="col-6 text-right">
                                                            <button
                                                                type="button"
                                                                className="p-1 btn btn-danger w-100"
                                                                data-bs-dismiss="modal"
                                                            >
                                                                {" "}
                                                                ไม่รับโปรฯ{" "}
                                                            </button>
                                                        </div>
                                                        <div className="col-6 text-left">
                                                            <button
                                                                type="button"
                                                                data-bs-dismiss="modal"
                                                                className="p-1 btn btn-success text-white w-100"
                                                            >
                                                                {" "}
                                                                รับโปรฯ{" "}
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={16}
                                                                    height={16}
                                                                    fill="currentColor"
                                                                    className="bi bi-gift-fill"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A2.968 2.968 0 0 1 3 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43a.522.522 0 0 0 .023.07zM9 3h2.932a.56.56 0 0 0 .023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0V3zm6 4v7.5a1.5 1.5 0 0 1-1.5 1.5H9V7h6zM2.5 16A1.5 1.5 0 0 1 1 14.5V7h6v9H2.5z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <div className="col-12 text-left mt-2">
                                                            <small className="text-danger">
                                                                * สามารถเปลี่ยนสถานะภายหลังได้
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3" style={{ display: this.state.tabselect == 21 ? "" : "none" }}>
                                    <div className="x-login-index animated animatedFadeInUp fadeInUp p-2">
                                        <div>
                                            <div>
                                                <div className="-inner-wrapper text-left">
                                                    <div className="bg-game-list mt-2 text-white">
                                                        <div className="d-flex justify-content-between p-2">
                                                            <div>
                                                                <a onClick={(e) => {
                                                                    this.checktab(e, 1)
                                                                }}>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={16}
                                                                        height={16}
                                                                        fill="currentColor"
                                                                        className="bi bi-arrow-left-circle"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                            <div />
                                                            <div>
                                                                <small>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={20}
                                                                        height={20}
                                                                        fill="currentColor"
                                                                        className="bi bi-controller"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1v-1z" />
                                                                        <path d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729c.14.09.266.19.373.297.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466.206.875.34 1.78.364 2.606.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527-1.627 0-2.496.723-3.224 1.527-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.34 2.34 0 0 1 .433-.335.504.504 0 0 1-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a13.748 13.748 0 0 0-.748 2.295 12.351 12.351 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.354 12.354 0 0 0-.339-2.406 13.753 13.753 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27-1.036 0-2.063.091-2.913.27z" />
                                                                    </svg>
                                                                    <label htmlFor="">&nbsp;ประเภท&nbsp;: ทรูมันนี่</label>
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {trueaccount !== null ?
                                                    <div className="text-white-v1-deposit text-center">
                                                        <h6 className="mb-2 mt-2 text-center">
                                                            <u>โอนเข้าบัญชี</u>
                                                        </h6>
                                                        <div>
                                                            <div className="flex justify-between mb-1 mx-auto box-content">
                                                                <div className="sub-content w-100 p-3 px-lg-5">
                                                                    <div className="text-center">
                                                                        <img
                                                                            className="rounded-full w-10 mb-2 flex"
                                                                            src="https://play-lh.googleusercontent.com/6I2IYbIg4rhGUgs0UxP_5q6wmJmlBjBrlQ9f0_FAN94yOzwmrtEteifCdPPd1-chY_NX"
                                                                            style={{ width: 38 }}
                                                                        />{" "}
                                                                        &nbsp;วอลเลท
                                                                    </div>
                                                                    <div className="text-center">
                                                                        {trueaccount.name} :&nbsp; {
                                                                            trueaccount.telnumber
                                                                        }
                                                                    </div>
                                                                    <div className="text-center pt-2">
                                                                        <a href={() => false} style={{ cursor: "pointer" }}
                                                                            onClick={() => navigator.clipboard.writeText(trueaccount.telnumber)}>
                                                                            <div
                                                                                className="btn v-login"
                                                                                style={{
                                                                                    fontSize: 16,
                                                                                    cursor: "pointer"
                                                                                }}
                                                                            >
                                                                                วอลเลท :&nbsp;{" "}
                                                                                <span id="copy-banktransfer">
                                                                                    {trueaccount.telnumber}
                                                                                </span>{" "}
                                                                                &nbsp;&nbsp;
                                                                                <span
                                                                                    className="badge text-bg-white p-1"
                                                                                    style={{
                                                                                        borderRadius: "33.9916px"
                                                                                    }}
                                                                                >
                                                                                    &nbsp; คัดลอก &nbsp;
                                                                                </span>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    <div className="text-center text-white bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                        <img
                                                            className="load-history"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                            alt=""
                                                        />
                                                        <div className="not-history font-history-menu-text p-2">
                                                            <small>
                                                                <b>ยังไม่ให้บริการ</b>
                                                            </small>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div>
                                                <div className="mt-3">
                                                    <div className="text-white-v1-deposit">
                                                        <div>
                                                            <div>
                                                                {trueaccount !== null ?
                                                                    <div className="text-white-v1-deposit text-center">
                                                                        <h6 className="mb-2 mt-2 text-center">
                                                                            <u>เติมของขวัญ</u>
                                                                        </h6>
                                                                        <div>
                                                                            <div className="flex justify-between mb-1 mx-auto box-content">
                                                                                <div className="sub-content w-100 p-3 px-lg-5">
                                                                                    <div className="text-center">
                                                                                        <img
                                                                                            className="rounded-full w-10 mb-2 flex"
                                                                                            src="https://play-lh.googleusercontent.com/6I2IYbIg4rhGUgs0UxP_5q6wmJmlBjBrlQ9f0_FAN94yOzwmrtEteifCdPPd1-chY_NX"
                                                                                            style={{ width: 38 }}
                                                                                        />{" "}
                                                                                        เติมผ่าน Link ของขวัญ โปรดใส่ลิ้งด้านล่าง
                                                                                    </div>
                                                                                    <div className="text-center pt-2">
                                                                                        <input
                                                                                            type="text"
                                                                                            id="depositamount"
                                                                                            onChange={this.linkdeposit}
                                                                                            // onClick={(e) => this.linkdeposit(e, 0)}
                                                                                            placeholder="ใส่ลิ้งของขวัญที่นี่"
                                                                                            className="form-control promptpay-border-radius"
                                                                                        />
                                                                                        <div className="pt-2 pb-2">
                                                                                            <button className="btn -btn-deposit w-100"
                                                                                                onClick={(e) => this.confirmdeposittrue(e, 0)}
                                                                                            >
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width={16}
                                                                                                    height={16}
                                                                                                    fill="currentColor"
                                                                                                    className="bi bi-check-circle-fill"
                                                                                                    viewBox="0 0 16 16"
                                                                                                >
                                                                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                                                                </svg>{" "}
                                                                                                เติมเงิน
                                                                                            </button>
                                                                                        </div>
                                                                                        {/* {trueaccount.name} :&nbsp; {
                                                                                                            trueaccount.telnumber
                                                                                                        } */}
                                                                                    </div>
                                                                                    {/* <div className="text-center pt-2">
                                                                                                    </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="popup-promotion"
                                        tabIndex={-1}
                                        aria-labelledby="popup-promotion-Label"
                                        aria-hidden="true"
                                        style={{ padding: 20 }}
                                    >
                                        <div className="modal-dialog modal-dialog-centered text-white">
                                            <div
                                                className="modal-content bg-popup-promotion"
                                                style={{ borderRadius: "15px !important" }}
                                            >
                                                <div className="mt-3">
                                                    <h1
                                                        className="modal-title fs-5 text-center"
                                                        id="popup-promotion-Label"
                                                    >
                                                        {" "}
                                                        รับโปรโมชั่นอัตโนมัติ{" "}
                                                    </h1>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="text-center row">
                                                        <div className="col-6 text-right">
                                                            <button
                                                                type="button"
                                                                className="p-1 btn btn-danger w-100"
                                                                data-bs-dismiss="modal"
                                                            >
                                                                {" "}
                                                                ไม่รับโปรฯ{" "}
                                                            </button>
                                                        </div>
                                                        <div className="col-6 text-left">
                                                            <button
                                                                type="button"
                                                                data-bs-dismiss="modal"
                                                                className="p-1 btn btn-success text-white w-100"
                                                            >
                                                                {" "}
                                                                รับโปรฯ{" "}
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={16}
                                                                    height={16}
                                                                    fill="currentColor"
                                                                    className="bi bi-gift-fill"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A2.968 2.968 0 0 1 3 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43a.522.522 0 0 0 .023.07zM9 3h2.932a.56.56 0 0 0 .023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0V3zm6 4v7.5a1.5 1.5 0 0 1-1.5 1.5H9V7h6zM2.5 16A1.5 1.5 0 0 1 1 14.5V7h6v9H2.5z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <div className="col-12 text-left mt-2">
                                                            <small className="text-danger">
                                                                * สามารถเปลี่ยนสถานะภายหลังได้
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container p-12" style={{ display: this.state.tabselect == 22 ? "" : "none" }}>
                                    <div className="row m-0 border-shadow-title">
                                        <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-deposit">
                                            <div className="mt-2 mb-2 text-center bg-title-web">
                                                <div>
                                                    <div className="text-title text-white text-center">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="text-white-deposit">
                                                                <a role="button" href='/'>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={16}
                                                                        height={16}
                                                                        fill="currentColor"
                                                                        className="bi bi-house-door"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                            <div className="text-white-deposit">
                                                                <h4> สเเกน QR ฝากเงิน </h4>
                                                            </div>
                                                            <div />
                                                        </div>
                                                        <hr className="mt-0" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3 text-white-deposit">
                                                <div className="animated animatedFadeInUp fadeInUp">
                                                    <div className="card-promptpay text-center text-white-deposit" style={{ display: this.state.qrcodecheck ? "none" : "" }}>
                                                        <img
                                                            className="img-logo-promptpay"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/9c9ef88c-a34b-4aa6-1a42-54ff6cd9a800/public"
                                                            alt=""
                                                        />
                                                        <span className="text-white-deposit">
                                                            {" "}
                                                            พร้อมเพย์ / ทรูมันนี่วอลเล็ท{" "}
                                                        </span>
                                                        <div className="pt-3 pb-2 text-left p-detail-promptpay">
                                                            <div className="pt-2 pb-2">
                                                                <small className="text-white-deposit p">
                                                                    {" "}
                                                                    ระบุจำนวนเงินที่ต้องเติม{" "}
                                                                </small>
                                                            </div>
                                                            <input
                                                                type="number"
                                                                placeholder="กรอกจำนวนเงิน"
                                                                id="deposit_amount"
                                                                onChange={this.onChange}
                                                                name="depositamount"
                                                                value={this.state.depositamount}
                                                                maxLength={4}
                                                                minLength={4}
                                                                className="form-control promptpay-border-radius"
                                                            />
                                                            <div className="mb-1 mt-2">
                                                                <label htmlFor="price" className="mt-1 mb-1 text-left">
                                                                    เลือกจำนวน
                                                                </label>
                                                                <div className="mb-4 row m-0">
                                                                    <div className="col-4 col-md-4 px-1">
                                                                        <div className="card-amount-qr mt-2 text-white-deposit">
                                                                            <div className="text-white-deposit" onClick={(e) => this.handleClickreposit(e, 100)}>

                                                                                100

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 col-md-4 px-1">
                                                                        <div className="card-amount-qr mt-2 text-white-deposit">
                                                                            <div className="text-white-deposit" onClick={(e) => this.handleClickreposit(e, 300)}>
                                                                                300
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 col-md-4 px-1">
                                                                        <div className="card-amount-qr mt-2 text-white-deposit">
                                                                            <div className="text-white-deposit" onClick={(e) => this.handleClickreposit(e, 500)}>
                                                                                500
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 col-md-4 px-1">
                                                                        <div className="card-amount-qr mt-2 text-white-deposit">
                                                                            <div className="text-white-deposit" onClick={(e) => this.handleClickreposit(e, 1000)}>

                                                                                1,000
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 col-md-4 px-1">
                                                                        <div className="card-amount-qr mt-2 text-white-deposit">
                                                                            <div className="text-white-deposit" onClick={(e) => this.handleClickreposit(e, 3000)}>

                                                                                3,000
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-4 col-md-4 px-1">
                                                                        <div className="card-amount-qr mt-2 text-white-deposit">
                                                                            <div className="text-white-deposit" onClick={(e) => this.handleClickreposit(e, 5000)}>

                                                                                5,000

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 col-md-4 px-1">
                                                                        <div className="card-amount-qr mt-2 text-white-deposit">
                                                                            <div className="text-white-deposit" onClick={(e) => this.handleClickreposit(e, 10000)}>

                                                                                10,000

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 col-md-4 px-1">
                                                                        <div className="card-amount-qr mt-2 text-white-deposit">
                                                                            <div className="text-white-deposit" onClick={(e) => this.handleClickreposit(e, 30000)}>
                                                                                30,000
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 col-md-4 px-1">
                                                                        <div className="card-amount-qr mt-2 text-white-deposit">
                                                                            <div className="text-white-deposit" onClick={(e) => this.handleClickreposit(e, 50000)}>
                                                                                50,000
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="pt-2 pb-2">
                                                                <button onClick={(e) => this.confirmdeposit(e, 0)} className="btn btn-primary w-100 text-white">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={16}
                                                                        height={16}
                                                                        fill="currentColor"
                                                                        className="bi bi-check-circle-fill"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                                    </svg>{" "}
                                                                    สร้าง QR Code เพื่อชำระ{" "}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-promptpay text-center pt-3" style={{ display: this.state.qrcodecheck ? "" : "none" }}>
                                                        <div className="card-body-promptpay">
                                                            <div className="card card-2-promptpay">
                                                                <div className="card-header card-2-promptpay-header">
                                                                    {" "}
                                                                    QR Code สำหรับชำระเงิน
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="p-2 mb-2 bg-success-subtle">
                                                                        <small className="text">
                                                                            QR Code
                                                                            สามารถสเเกนจ่ายได้เพียงครั้งเดี่ยวเท่านั้นโปรดอย่าสเเกนจ่ายซ้ำ
                                                                        </small>
                                                                    </div>
                                                                    <div className="prt-2">
                                                                        <div className="img-qr-code">
                                                                            {this.state.qrcode !== null ?
                                                                                <img
                                                                                    src={this.state.qrcode}
                                                                                    className="-img-text-promotion img-fluid ls-is-cached lazyloaded"
                                                                                    alt=""
                                                                                    style={{ backgroundColor: "black", height: 196, width: 196 }}
                                                                                    height={196}
                                                                                    width={196}
                                                                                /> : ""
                                                                            }
                                                                        </div>
                                                                        <div className="qtx-number pt-2">
                                                                            <h5 className="font-qtx-number">
                                                                                <b>
                                                                                    {this.state.depositamount}{" "}
                                                                                    <span>
                                                                                        <img
                                                                                            className="lang-type-bath"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d729c93e-e40d-4c6f-db8b-be26f954d200/public"
                                                                                            alt=""
                                                                                        />
                                                                                    </span>
                                                                                </b>
                                                                            </h5>
                                                                        </div>
                                                                        <hr className="m-1" />
                                                                        <div className="qtx-date-time">
                                                                            <h5 className="font-qtx-date-time m-0">
                                                                                <b>{this.state.counter > 0 ? this.state.counter + " วินาที" : ""} </b>
                                                                            </h5>
                                                                            <h5 className="text-danger remark-date-time m-0">
                                                                                โปรดชำระเงินภายในเวลาที่กำหนด
                                                                            </h5>
                                                                        </div>
                                                                        <hr className="m-1" />
                                                                        <div className="bg-success-subtle p-2 mb-2 text-color-details">
                                                                            <div className="font-details-qr-code">
                                                                                {" "}
                                                                                หากเกิดปัญหาฝากเงินหรืออื่นๆ
                                                                                กรุณาติดต่อเจ้าหน้าที่{" "}
                                                                                <b className="text-danger">
                                                                                    <a role="button">
                                                                                        Line ID : {this.state.linelink}
                                                                                    </a>
                                                                                </b>
                                                                            </div>
                                                                        </div>
                                                                        <a role="button" className="btn btn-danger w-100">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width={16}
                                                                                height={16}
                                                                                fill="currentColor"
                                                                                className="bi bi-x-circle-fill"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                                            </svg>{" "}
                                                                            {this.state.counter > 0 ? "ยกเลิกรายการ" : "กรุณาทำรายการใหม่"}
                                                                        </a>
                                                                        {/**/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-deposit">
                                            <div className="text-white-deposit text-center">
                                                <div className="box-content p-0">
                                                    <div className="sub-content p-3">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="text-white-deposit pt-1">
                                                                <img
                                                                    src="/assets/ic-header-menu-promotion.d5c30a79.png"
                                                                    alt=""
                                                                    style={{ width: 30 }}
                                                                />{" "}
                                                                &nbsp; รับโปรโมชั่นอัตโนมัติ
                                                            </div>
                                                            <div className="text-white-deposit">
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        id="flexSwitchCheckDefault"
                                                                        style={{ width: "6em", padding: 12 }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                <div className="mt-2 text-white" style={{ display: this.state.tabselect == 23 ? "" : "none" }}>
                                    <div className="x-login-index animated animatedFadeInUp fadeInUp p-2">
                                        <div className="container">
                                            <div>
                                                <div className="-inner-wrapper text-left">
                                                    <div className="bg-game-list mt-2 text-white">
                                                        <div className="d-flex justify-content-between p-2">
                                                            <div>
                                                                <a onClick={(e) => {
                                                                    this.checktab(e, 1)
                                                                }}>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={16}
                                                                        height={16}
                                                                        fill="currentColor"
                                                                        className="bi bi-arrow-left-circle"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                            <div />
                                                            <div>
                                                                <small>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={20}
                                                                        height={20}
                                                                        fill="currentColor"
                                                                        className="bi bi-controller"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1v-1z" />
                                                                        <path d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729c.14.09.266.19.373.297.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466.206.875.34 1.78.364 2.606.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527-1.627 0-2.496.723-3.224 1.527-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.34 2.34 0 0 1 .433-.335.504.504 0 0 1-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a13.748 13.748 0 0 0-.748 2.295 12.351 12.351 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.354 12.354 0 0 0-.339-2.406 13.753 13.753 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27-1.036 0-2.063.091-2.913.27z" />
                                                                    </svg>
                                                                    <label htmlFor="">&nbsp;ประเภท&nbsp;: ธนาคาร</label>
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <div>
                                                            <div className="text-white-v1">
                                                                <div>
                                                                    <div className="mb-1">
                                                                        <label
                                                                            htmlFor="price"
                                                                            className="mt-1 mb-1 text-left"
                                                                        >
                                                                            จำนวนเงิน
                                                                        </label>
                                                                        <div className="-x-input-icon flex-column">
                                                                            <div className="input-group mb-1">
                                                                                <input
                                                                                    id="withdraw_amount"
                                                                                    onChange={this.onChange}
                                                                                    name="withdrawamount"
                                                                                    value={this.state.withdrawamount}
                                                                                    className="form-control"
                                                                                    placeholder={0}
                                                                                    aria-label={0}
                                                                                    aria-describedby="basic-addon1"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-1">
                                                                        <label
                                                                            htmlFor="price"
                                                                            className="mt-1 mb-1 text-left"
                                                                        >
                                                                            เลือกจำนวน
                                                                        </label>
                                                                        <div className="mb-4 row m-0">
                                                                            <div className="col-4 col-md-4 px-1">
                                                                                <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 100)}>
                                                                                    <div className="text-white-v1">
                                                                                        100
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 px-1">
                                                                                <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 300)}>
                                                                                    <div className="text-white-v1">
                                                                                        300
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 px-1">
                                                                                <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 500)}>
                                                                                    <div className="text-white-v1">
                                                                                        500
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 px-1">
                                                                                <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 1000)}>
                                                                                    <div className="text-white-v1">
                                                                                        1,000
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 px-1">
                                                                                <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 3000)}>
                                                                                    <div className="text-white-v1">
                                                                                        3,000
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 px-1">
                                                                                <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 5000)}>
                                                                                    <div className="text-white-v1">
                                                                                        5,000
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 px-1">
                                                                                <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 10000)}>
                                                                                    <div className="text-white-v1">
                                                                                        10,000
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 px-1">
                                                                                <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 30000)}>
                                                                                    <div className="text-white-v1">
                                                                                        30,000
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-4 px-1">
                                                                                <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 50000)}>
                                                                                    <div className="text-white-v1">
                                                                                        50,000
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/**/}
                                                                <div>
                                                                    <div className="mt-1 mb-4">
                                                                        <div>
                                                                            <button
                                                                                onClick={(e) => this.submitwithdraw(e)}
                                                                                className="btn -submit js-submit-accept-term v-login btn-success mt-lg-1 mt-0 w-100 p-2">
                                                                                ถัดไป
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/**/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </div>
                                <div className="x-margin-top-v3 text-white" style={{ display: this.state.tabselect == 24 ? "" : "none" }}>
                                    <div className="animated animatedFadeInUp fadeInUp m-2">
                                        <div className="mt-3">
                                            <h3 className="x-title-modal mx-auto text-center mb-3">
                                                <b>ภารกิจ</b>
                                            </h3>
                                            <div className="tap-mission p-2">
                                                <div className="d-flex justify-content-between">
                                                    <div className="w-100">
                                                        <div className="m-1 step-mission-active"> ภารกิจ </div>
                                                    </div>
                                                    <div className="w-100">
                                                        <div className="m-1">ประวัติ</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-2 mb-2">
                                                <div className="animated animatedFadeInUp fadeInUp">
                                                    <div className="tap-mission-started">
                                                        <div className="d-flex justify-content-between">
                                                            <div
                                                                className="w-100 started text-center step-started-active"
                                                                role="button"
                                                            >
                                                                <div className="w-100-title p-1 text-center">ทั้งหมด</div>
                                                            </div>
                                                            <div className="w-100 started text-center" role="button">
                                                                <div className="w-100-title p-1 text-center">ฝาก</div>
                                                            </div>
                                                            <div className="w-100 started text-center" role="button">
                                                                <div className="w-100-title p-1 text-center">เล่นเกม</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 mb-2">
                                                        <div className="animated animatedFadeInUp fadeInUp">
                                                            <div>
                                                                <div>
                                                                    <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                                        <b>
                                                                            <img
                                                                                className="load-history"
                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                                alt=""
                                                                            />
                                                                            <div className="not-history font-history-menu-text p-2">
                                                                                <small>
                                                                                    <b>ไม่พบข้อมูล</b>
                                                                                </small>
                                                                            </div>
                                                                        </b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                        {/**/}
                                                        {/**/}
                                                    </div>
                                                </div>
                                                {/**/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container p-0 mt-3" style={{ display: this.state.tabselect == 25 ? "" : "none" }}>
                                    <div className="m-2 border-shadow-title animated animatedFadeInUp fadeInUp">
                                        <div
                                            data-v-ab1d19ba=""
                                            className="auction top-loss animated animatedFadeInUp fadeInUp"
                                            role="button"
                                        >
                                            <div
                                                data-v-ab1d19ba=""
                                                className="auction__body-menu auction__body--border-radius"
                                            >
                                                <div data-v-ab1d19ba="" className="tab text-color">
                                                    <h3 className="x-title-modal mx-auto text-center mt-5">
                                                        <b>กิจกรรมเปิดไพ่</b>
                                                    </h3>
                                                    <hr />
                                                    <div>
                                                        <div>
                                                            <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                                <b>
                                                                    <img
                                                                        className="load-history"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                        alt=""
                                                                    />
                                                                    <div className="not-history font-history-menu-text p-2">
                                                                        <small>
                                                                            <b>ไม่พบข้อมูล</b>
                                                                        </small>
                                                                    </div>
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container p-0 mt-3" style={{ display: this.state.tabselect == 26 ? "" : "none" }}>
                                    <div className="m-2 border-shadow-title animated animatedFadeInUp fadeInUp">
                                        <div
                                            data-v-ab1d19ba=""
                                            className="auction top-loss animated animatedFadeInUp fadeInUp"
                                            role="button"
                                        >
                                            <div
                                                data-v-ab1d19ba=""
                                                className="auction__body-menu auction__body--border-radius"
                                            >
                                                <div data-v-ab1d19ba="" className="tab text-color">
                                                    <h3 className="x-title-modal mx-auto text-center mt-5">
                                                        <b>แลกรางวัล</b>
                                                    </h3>
                                                    <hr />
                                                    <div>
                                                        <div>
                                                            <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                                <b>
                                                                    <img
                                                                        className="load-history"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                        alt=""
                                                                    />
                                                                    <div className="not-history font-history-menu-text p-2">
                                                                        <small>
                                                                            <b>ไม่พบข้อมูล</b>
                                                                        </small>
                                                                    </div>
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div style={{ display: this.state.tabselect == 0 ? "" : "none" }}>
                                <div className="row m-1" >
                                    <div className="p-0 text-center animated animatedFadeInUp fadeInUp col-12">
                                        <img
                                            role="button"
                                            src="/build/web/igame-index-lobby-wm/img/ef394ee0-739f-49df-88bf-cfa3bc7c2829.png"
                                            className="border-home p-1 w-100 img-home-category"
                                            alt=""
                                        />
                                    </div>
                                    <a
                                        onClick={(e) => {
                                            this.checkurl(e, '/casino')
                                            // window.location.href = '/casino'
                                        }}
                                        className="p-0 text-center animated animatedFadeInUp fadeInUp col-4 p-1">
                                        <img
                                            role="button"
                                            src="/build/web/igame-index-lobby-wm/img/casino.png"
                                            className="w-100 img-home-category"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            this.checkurl(e, '/slot')
                                            // window.location.href = '/slot'
                                        }}
                                        className="p-0 text-center animated animatedFadeInUp fadeInUp col-4 p-1">
                                        <img
                                            role="button"
                                            src="/build/web/igame-index-lobby-wm/img/slot.png"
                                            className="w-100 img-home-category"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            this.checkurl(e, '/gamehit')
                                            //  window.location.href = '/gamehit'
                                        }}
                                        className="p-0 text-center animated animatedFadeInUp fadeInUp col-4 p-1">
                                        <img
                                            role="button"
                                            src="/build/web/igame-index-lobby-wm/img/gamehit.png"
                                            className="w-100 img-home-category"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            this.checkurl(e, '/fishing')
                                            //  window.location.href = '/fishing'
                                        }}
                                        className="p-0 text-center animated animatedFadeInUp fadeInUp col-4 p-1">
                                        <img
                                            role="button"
                                            src="/build/web/igame-index-lobby-wm/img/fish.png"
                                            className="w-100 img-home-category"
                                            alt=""
                                        />
                                    </a>

                                    <a
                                        onClick={(e) => {
                                            this.checkurl(e, '/sport')
                                            //  window.location.href = '/sport'
                                        }}
                                        className="p-0 text-center animated animatedFadeInUp fadeInUp col-4 p-1">
                                        <img
                                            role="button"
                                            src="/build/web/igame-index-lobby-wm/img/sport.png"
                                            className="w-100 img-home-category"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            this.checkurl(e, '/lotto')
                                            //  window.location.href = '/lotto'
                                        }}
                                        className="p-0 text-center animated animatedFadeInUp fadeInUp col-4 p-1">
                                        <img
                                            role="button"
                                            src="/build/web/igame-index-lobby-wm/img/huay.png"
                                            className="w-100 img-home-category"
                                            alt=""
                                        />
                                    </a>
                                </div>
                                <div>
                                    <main className="container p-0">
                                        <div className="p-1 mb-2 text-left container-xl text-white mt-1 my5 animated animatedFadeInUp fadeInUp">
                                            <div>
                                                <img
                                                    className="w-100 mt-2 mb-2 responsive-line"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d289b84a-2fc8-413e-e589-d5570ca44a00/public"
                                                />
                                            </div>
                                            <div className="clearfix">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h4 className="ps-2 mb-0 pt-1 f-22 text-title">
                                                            <b>เกมส์ยอดนิยม</b>
                                                        </h4>
                                                    </div>
                                                    <div>
                                                        <img
                                                            role="button"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2868e8f7-a36f-48a6-bbe4-e71172fc4c00/public"
                                                            alt=""
                                                            className="w-icon-cr-bl-record-all"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-center text-white">loading...</div>
                                        </div>
                                    </main>
                                </div>
                                {/**/}
                                <div className="game-show">
                                    <div>
                                        <div className="p-2" data-v-d899973a="">
                                            <div className="clearfix" data-v-d899973a="">
                                                <div className="row m-0" data-v-d899973a="">
                                                    <div
                                                        className="col-md-12 col-12 text-left pl-0 pr-0"
                                                        data-v-d899973a=""
                                                    >
                                                        <h4
                                                            className="ps-2 mb-0 pt-1 f-22 text-title"
                                                            data-v-d899973a=""
                                                        >
                                                            <b data-v-d899973a="">บทความ </b>
                                                            <span className="text-green" data-v-d899973a="">
                                                                <img
                                                                    className="img-title-web"
                                                                    src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                                                                    alt=""
                                                                    data-v-d899973a=""
                                                                />
                                                            </span>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-1" data-v-d899973a="">
                                                <div className="row pb-3" data-v-d899973a="">
                                                    <div className="col-12" data-v-d899973a="">
                                                        <img
                                                            className="w-100 border-article"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/fbfd457a-512e-4a35-54ca-5cfc6f205c00/public"
                                                            alt=""
                                                            data-v-d899973a=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-article" data-v-d899973a="">
                                                    <h5 data-v-d899973a="">
                                                        <strong data-v-d899973a="">บทความโรลริ่ง 1% </strong>
                                                    </h5>
                                                    <p data-v-d899973a="">
                                                        ต้องดูแทงบาคาร่าเสียเงินแต่ผลประกอบการคือกำไร เพราะรู้จัก
                                                        Rolling 1%
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        ในวันนี้เราจะมาอธิบายให้ทุกคนที่เล่นบาคาร่าได้รู้จักหัวใจที่สำคัญที่สุด
                                                        ที่มันจะช่วยเพิ่มเปอร์เซ็นต์ชนะ และลดการขาดทุน&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        ที่คนส่วนใหญ่ไม่เคยรู้ นั่นคือ Rolling อัตโนมัติ 1%
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        Rolling คือศัพท์ในวงการบาคาร่า ถ้าจะแปลง่ายๆก็คือค่าคอมมิชชั่น
                                                        ที่ทุกคนจะได้รับกลับคืนมานั่นเอง ถ้าไพ่บาคาร่าโอกาสแพ้ชนะคือ
                                                        50 50&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        งั้นเราจะเอากฎ 50 นี้มาใช้กัน โดยทุกเกมที่แทง คือแทงที่ราคา
                                                        1,000 บาท เกมที่ 1 แทง Player ชนะ เกมที่ 2 แทง Player
                                                        แพ้&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        เกมที่ 3 แทงแบงค์เกอร์ชนะ และเกมที่ 4 แทงแบงค์เกอร์แพ้
                                                        เมื่อครบ 4 เกม เราจะขาดทุนทันที 50 บาท
                                                        เพราะเกมที่เราแทงแบงค์เกอร์ชนะเราจะโดนหักไป 5% นั่นเอง
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        &nbsp;แต่พอเรารู้จัก Rolling 1% เราจะได้ค่า Rolling
                                                        กลับคืนมาถึง 40 บาท ทำให้ จากกจะขาดทุน 50 บาท กลายเป็นขาดทุน
                                                        10 บาทเท่านั้น
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        &nbsp;นั่นเท่ากับว่าโลลิ่ง 1%
                                                        สามารถเซฟการขาดทุนของคุณได้มากถึง 80% เลยทีเดียว 80%
                                                        เลยทีเดียว นี่เป็นแค่ตัวอย่างเท่านั้น
                                                        แต่มีอีกหลายเหตุการณ์ที่วันนี้ ไม่ใช่วันของคุณ
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        คุณเสียเงินให้บาคาร่า แต่ผลประกอบการคุณ กลับเป็นบวก เพราะมี
                                                        Rolling มาช่วย
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        ผมขออธิบายก่อนนะครับ คำว่าจำนวนหมายถึงยอดเล่นทั้งหมด
                                                        และคำว่าจำนวนที่ถูกต้องหมายถึง ยอดแพ้ชนะ
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        พูดง่ายๆคือหักยอดเสมอไป และยอดตรงนี้ล่ะครับที่จะมาคำนวณเป็น
                                                        Rolling ส่วนการนับเดิมพันหมายถึง
                                                        จำนวนเกมที่คุณเล่นไปในวันนั้น&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        เช่นถ้าคุณแทงน้ำเงิน 5 เกม แดง 5 เกม
                                                        การนับเดิมพันคุณก็จะเป็น10เป็นต้น ตรงชนะแพ้หมายถึง
                                                        วันนี้คุณได้หรือเสีย&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        คอมมิชชั่นตรงนี้แหละครับคือ Rolling ที่คุณได้รับในวันนี้
                                                        และส่วนสุดท้ายก็คือเอายอดชนะแพ้ มาหักลบกับค่าคอมนั่นเอง
                                                    </p>
                                                </div>
                                                <br data-v-d899973a="" />
                                                <div className="text-3" data-v-d899973a="" />
                                                <div className="row pb-3" data-v-d899973a="">
                                                    <div className="col-12" data-v-d899973a="">
                                                        <img
                                                            className="w-100 border-article"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/31e68827-fa81-49eb-89f6-4fb831c4dc00/public"
                                                            alt=""
                                                            data-v-d899973a=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-1 text-article" data-v-d899973a="">
                                                    <h5 data-v-d899973a="">
                                                        <strong data-v-d899973a="">
                                                            เผยสูตรเล่นคาสิโนตามแบบฉบับเซียนพนันสายทำเงิน{" "}
                                                        </strong>
                                                    </h5>
                                                    <p data-v-d899973a="">
                                                        &nbsp; &nbsp;
                                                        &nbsp;คาสิโนถือว่าเป็นสถานที่ที่รวบรวมเกมพนันไว้มากมายกว่า 10
                                                        ชนิด ปัจจุบันได้ถูกนำมาพัฒนาให้สามารถเล่นบนเว็บไซต์ได้อีกด้วย
                                                        จึงทำให้มีนักเสี่ยงโชคหลายคนอยากทำเงินหรือได้เงินดูสักครั้ง
                                                        วันนี้เราจะมาเผยสูตรเล่นคาสิโนตามแบบฉบับเซียนพนันสายทำเงิน
                                                        จะมีสูตรอะไรกันบ้างไปดูกันเลย&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        1. กำหนดเงินทุน ไม่ว่าคุณจะมีเงินทุนมากน้อยเพียงใด
                                                        ก็สามารถทำเงินได้หากรู้จักกำหนดเงินทุน
                                                        ทำให้ผู้เล่นรู้ว่าต้องแทงเท่าไร แทงเมื่อไหร่ แทงตอนไหน
                                                        หรือเล่นกี่ครั้งจึงจะได้กำไรกลับมา
                                                        ช่วยปรับให้การเล่นเป็นขั้นเป็นตอนมากยิ่งขึ้น
                                                        เมื่อเปรียบเทียบกับการเล่นไปเรื่อยโดยที่ไม่มีจุดหมาย
                                                        พบว่าทำเงินได้แน่นอนว่าและมีโอกาสน้อยที่จะขาดทุน&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        2. มองหาจุดอ่อน
                                                        เชื่อว่าหรือว่าการพนันแทบทุกชนิดมีจุดอ่อนกันทั้งนั้น
                                                        เพียงแต่ต้องใช้ประสบการณ์ในการเล่น
                                                        เพื่อทำให้มองหาจุดอ่อนเหล่านี้ได้อย่างทะลุปุโปร่ง
                                                        จุดอ่อนเหล่านี้ช่วยทำให้เราแทงพนันได้อย่างถูกต้อง แม่นยำ
                                                        และทำกำไรได้ในระยะเวลาอันสั้น
                                                        สำหรับท่านที่หาไม่ได้สามารถหาได้จากเว็บไซต์คาสิโนออนไลน์
                                                        หรือสอบถามจากเซียนพนันมืออาชีพโดยตรง&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        3. เรียนรู้วิธีการเล่น
                                                        สูตรนี้อาจเป็นสูตรที่ฟังดูง่ายๆแต่นักพนันหลายคนอาจจะนึกไม่ถึง
                                                        แต่รู้หรือไม่ว่ายิ่งเรียนรู้วิธีการเล่นมากเท่าไร เช่น
                                                        วิธีการนับแต้ม วิธีการเดิมพัน ขั้นต่ำในการเดิมพัน วิธีจั่วไพ่
                                                        หรือวิธีแทงพนัน ยิ่งช่วยเพิ่มโอกาสทำเงินมากเท่านั้น
                                                        ลดความสับสนระหว่างเล่น ทำให้เดิมพันได้อย่างไม่ใจ
                                                        และป้องกันไม่ให้เสียพนันโดยไม่ตั้งใจ
                                                        ฉะนั้นควรเวลาทบทวนและศึกษาวิธีการเล่นอย่างต่อเนื่อง&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        4. ไม่ใช้อารมณ์
                                                        รู้หรือไม่ว่าอารมณ์เป็นศัตรูตัวสำคัญที่ทำให้เสียพนันได้
                                                        ไม่ว่าคุณจะได้เงิน เสียพนัน หรือแพ้จนแทบจะหมดตัว
                                                        ไม่ควรนำเอาความโกรหรือความแค้นมาเป็นส่วนหนึ่งของเกมเล่น
                                                        ควรวางมันไว้นอกโต๊ะนอกเกมให้หมด
                                                        พวกนี้อาจทำให้เสียพนันหรือนำไปสู่การหมดเนื้อหมดตัวได้ในอนาคต&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        5. เล่นให้บ่อยที่สุด ไม่มีใครเก่งมาตั้งแต่เกิด
                                                        คำนี้น่าจะพอทำให้เห็นภาพมากยิ่งขึ้น
                                                        ฉะนั้นควรใช้เวลาในการเล่นให้มากที่สุด
                                                        ยิ่งเล่นบ่อยยิ่งทำให้มองเห็นโอกาส มองเห็นจุด
                                                        และทำความเข้าใจไปพร้อมๆกัน
                                                        สูตรเล่นคาสิโนที่เราได้นำมาแนะนำกันในวันนี้
                                                        นำมาจากเซียนพนันสายทำเงินโดยตรง
                                                        จึงสามารถรับประกันว่ามันช่วยทำเงินได้จริง ๆ
                                                        ยิ่งนำไปปรับเปลี่ยนกับการเล่นของตนเองได้ไวเท่าไร
                                                        ยิ่งหาเงินกำไรได้รวดเร็วมากขึ้นเท่านั้น
                                                        สามารถนำไปใช้เล่นบนเว็บคาสิโนออนไลน์ทุกเว็บ
                                                        ไม่ได้ผิดกฏแถมยังเป็นตัวช่วยชั้นดีที่ไม่ต้องเสียเงินเพิ่มใดๆ
                                                    </p>
                                                </div>
                                                <br data-v-d899973a="" />
                                                <div className="row pb-3" data-v-d899973a="">
                                                    <div className="col-12" data-v-d899973a="">
                                                        <img
                                                            className="w-100 border-article"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/53aefd90-f48a-428e-5580-0ae761b25200/public"
                                                            alt=""
                                                            data-v-d899973a=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-2 text-article" data-v-d899973a="">
                                                    <h5 data-v-d899973a="">
                                                        <strong data-v-d899973a="">
                                                            สิทธิพิเศษที่หาได้จากการเล่นคาสิโนออนไลน์เท่านั้น{" "}
                                                        </strong>
                                                    </h5>
                                                    <p data-v-d899973a="">
                                                        &nbsp; &nbsp; &nbsp;คาสิโนออนไลน์ คือ
                                                        สถานที่เดิมพันแห่งใหม่ที่ได้รับความนิยมอย่างมากในยุคนี้
                                                        อัดแน่นไปด้วยการพนันมากมายหลายชนิด เช่น บาคาร่า
                                                        รูเล็ตโป๊กเกอร์ แบล็คแจ็ค ป๊อกเด้ง เสือมังกร สล็อต
                                                        หรือการพนันกีฬาอีกมากมาย ทั้งหมดนี้สามารถหาเล่นได้จบในที่เดียว
                                                        &nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        &nbsp; &nbsp;
                                                        &nbsp;ปัจจุบันยิ่งเป็นยุคที่อินเตอร์เน็ตหรือสื่อออนไลน์เข้ามามีบทบาทเท่าไร
                                                        ยิ่งเพิ่มจำนวนของผู้เล่นสูงขึ้น
                                                        เพราะมันสะดวกสบายไม่ต้องเสียเวลาเดินทาง
                                                        มีระบบสมาชิกที่ปลอดภัยไร้กังวล
                                                        และเงินรางวัลจำนวนมากที่กำลังรออยู่
                                                        แต่ไม่ได้มีเพียงแต่เหตุผลทั้งหมดที่เราแนะนำไว้ข้างต้น
                                                        เพราะวันนี้เราได้รวบรวมสิทธิพิเศษที่สามารถหาได้จากการเล่นออนไลน์มาฝากกัน
                                                        &nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        1.เล่นได้ตลอด 24 ชั่วโมง สิทธิพิเศษแรกที่ไม่มีใครเหมือนเลย คือ
                                                        ทุกท่านสามารถเล่นได้ตลอด 24 ชั่วโมง ไม่ว่าจะอยู่ที่้บ้าน
                                                        อยู่ที่ทำงาน หรือขณะเดินทางไปไหน ทุกเกม ทุกระบบ
                                                        ทุกบริการพร้อมเปิดใช้งาน พยายามปรับปรุงเว็บไซต์ให้ดีอยู่เสมอ
                                                        ไม่ให้มีปัญหาติดขัด
                                                        และสร้างความเพลิดให้กับผู้เล่นที่เข้ามาเสมอ&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        2.โปรโมชั่นเพียบ
                                                        นี่คือสิ่งที่คาสิโนออนไลน์สามารถสร้างความโดดเด่นได้เป็นอย่าง
                                                        เป็นโปรโมชั่นที่ถูกสร้างสรรค์เพื่อมอบโบนัสต่างๆให้ผู้เล่นมากมาย
                                                        เริ่มต้นตั้งแต่โปรโมชั่นสมัครสมาชิกใหม่ ให้โบนัสสูงสุดถึง 50%
                                                        จากยอดสมัครครั้งแรก, โปรโมชั่นเงินฝากประจำวัน
                                                        มอบโบนัสสูงสุดกว่า 20%
                                                        ให้กับนักพนันที่มียอดฝากแรกเป็นประจำทุกวัน,
                                                        โปรโมชั่นคืนยอดเสีย
                                                        มอบเงินคืนให้กับยอดเสียรวมรายสัปดาห์ให้กับนักพนันโชคร้าย
                                                        หรือโปรโมชั่นชวนเพื่อนสมัครสมาชิก
                                                        ยิ่งชวนมากยิ่งได้เงินโบนัสมากเช่นกัน เป็นต้น
                                                        เป็นตัวช่วยสำคัญที่ทำให้เงินทุนเพิ่มขึ้น
                                                        โอกาสทำเงินทำไรสูงกว่าเก่า
                                                        และลดโอกาสเสียพนันให้น้อยลงอีกด้วย&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        3.ดาวน์โหลดลงมือถือได้ สำหรับท่านที่มี มือถือ สมาร์ทโฟน
                                                        หรือแทปเล็ต ทุกรุ่น ทั้งจาก Andriod หรือ iOS
                                                        สามารถดาวน์โหลดมาติดตั้งได้แล้ววันนี้
                                                        ช่วยเพิ่มความสะดวกสบายได้มากกว่าที่เคยสัมผัสมา
                                                        สามารถเล่นได้ทุกเกมจากเว็บคาสิโนออนไลน์ได้ตามปกติ
                                                        แถมยังมีโบนัสอื่น ๆ สำหรับผู้ที่เล่นผ่านมือให้อีกด้วย
                                                        ลองนึกภาพว่าคุณสามารถพกพาบาคาร่า โป๊กเกอร์ แบล็คแจ็ค
                                                        หรือสล็อตออนไลน์ไว้เล่นยามเงายามเบื่อจะดีแค่ไหน
                                                        สิทธิพิเศษนี้สามารถตอบโจทย์ได้เป็นอย่างดี&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        &nbsp; &nbsp;
                                                        &nbsp;ปัจจุบันคาสิโนออนไลน์ยินดีที่จะเพิ่มสิทธิพิเศษใหม่ๆให้กับนักพนันได้รับความประทับมากยิ่งขึ้น
                                                        ท่านที่ยังไม่เคยเล่นผ่านสถานที่รูปแบบใหม่นี้มาก่อน
                                                        แนะนำว่าไม่ควรพลาดอย่างยิ่ง สมัครวันนี้ สนุกได้วันนี้
                                                        และรับสิทธิพิเศษได้วันนี้ ไม่ต้องรอไม่ลุ้นอีกต่อไป
                                                        รับประกันว่าไม่มีทางหาได้จากสถานที่เล่นพนันที่อื่นแน่นอน
                                                    </p>
                                                </div>
                                                <br data-v-d899973a="" />
                                                <div className="row pb-3" data-v-d899973a="">
                                                    <div className="col-12" data-v-d899973a="">
                                                        <img
                                                            className="w-100 border-article"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8236db76-06f0-4a0e-e839-a484b7ae8100/public"
                                                            alt=""
                                                            data-v-d899973a=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-3 text-article" data-v-d899973a="">
                                                    <h5 data-v-d899973a="">
                                                        <strong data-v-d899973a="">
                                                            ในบทความนี้เราจะพาทุกท่านไปสอนแบบเจาะลึกถึง วิธีแทงบอล
                                                            พร้อมสอนแบบจับมือทำ พร้อมแล้วไปลุยกันเลย
                                                        </strong>
                                                    </h5>
                                                    <p data-v-d899973a="">4 ข้อควรรู้ก่อนเข้าสู่ วิธีแทงบอล</p>
                                                    <p data-v-d899973a="">
                                                        1. เริ่มต้นด้วยการเลือกเว็บไซต์แทงบอล จุดเริ่มต้นของทุก
                                                        วิธีการแทงบอล คือการเลือกเว็บไซต์พนันบอลให้ถูก
                                                        นักพนันจะต้องรู้จักเลือกเว็บไซต์ที่จะใช้บริการก่อน
                                                        โดยเว็บนั้นจะต้องมาพร้อมบริการคุณภาพ
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        2. รู้จักรูปแบบการวางเดิมพันฟุตบอลทั้ง 6 รูปแบบ
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        รูปแบบของการเดิมพันถือเป็นจุดเด่นของการแทงบอลเลยก็ว่าได้
                                                        เพราะการเดิมพันฟุตบอลออนไลน์นั้น
                                                        นักเดิมพันสามารถเลือกเดิมพันได้หลากหลายรูปแบบ
                                                        ซึ่งรูปแบบที่ได้รับความนิยม
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        3. รู้จักความแตกต่างระหว่างการแทงบอลแบบเต็งและแบบสเต็ป
                                                        นักเดิมพันที่เป็นมือใหม่
                                                        อาจจะเคยได้ยินคำว่าการเดิมพันฟุตบอลออนไลน์แบบเต็ง
                                                        และการเดิมพันฟุตบอลออนไลน์แบบสเต็ปกันมาบ้างแล้ว
                                                        ซึ่งการเดิมพันฟุตบอลออนไลน์ทั้ง 2 รูปแบบ มีความแตกต่างกัน
                                                    </p>
                                                    <p data-v-d899973a="">4. รู้จัก วิธีแทงบอล</p>
                                                    <p data-v-d899973a="">
                                                        เมื่อนักเดิมพันได้ทำความเข้าใจเกี่ยวกับการรายละเอียดของการแทงบอลออนไลน์แล้ว
                                                        ก็ควรได้ทราบถึงขั้นตอนการแทงบอลออนไลน์ด้วยเช่นเดียวกัน
                                                    </p>
                                                </div>
                                                <br data-v-d899973a="" />
                                                <div className="row pb-3" data-v-d899973a="">
                                                    {/* <div className="col-12" data-v-d899973a="">
                                                        <img
                                                            className="w-100 border-article"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/c4bf9747-a857-4704-adee-41d977ae8600/public"
                                                            alt=""
                                                            data-v-d899973a=""
                                                        />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
                <div
                    className={(this.state.showmodal) ? "modal fade show" : ""}
                    id="show_img"
                    aria-labelledby="show_imgLabel"
                    tabIndex={-1}
                    aria-modal="true"
                    role="dialog"
                    style={{ display: (this.state.showmodal) ? "block" : "none" }}
                >
                    <div className="modal-dialog modal-dialog-centered" style={{ backgroundColor: "black" }}>
                        <div className="modal-content bg-popup-modal p-2">
                            <div className="modal-header pt-0 pb-0">
                                <a
                                    href="#0"
                                    onClick={(e) =>
                                        this.setState({
                                            showmodal: false,
                                        })
                                    }
                                >
                                    <small
                                        className="text-white-v1"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={30}
                                            height={30}
                                            fill="currentColor"
                                            className="bi bi-x-circle"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </small>
                                </a>
                            </div>
                            <div className="modal-body">

                                {(prodetail.length === 0) ?
                                    <div>
                                        <img
                                            style={{ display: (prodetail.linkpicture !== null) ? "" : "none" }}
                                            src={picnonpro}
                                            alt="img_show_popup"
                                            className="img_show_popup"
                                        />
                                        <div className="box-content-pro mt-2 pt-2 pb-2">
                                            <div className="sub-content-pr0 p-2">ข้อตกลงเเละเงื่อนไข</div>
                                        </div>
                                        <div className="text-left"> <pre
                                            className="text-color-promotion"
                                            style={{ whiteSpace: "pre-line" }}
                                        >โปรโมชั่นยังไม่ได้เปิดใช้งาน
                                        </pre>
                                        </div> </div> : <div>
                                        <img

                                            style={{ display: (prodetail.linkpicture !== null) ? "" : "none" }}
                                            src={prodetail.linkpicture}
                                            alt="img_show_popup"
                                            className="w-100"
                                        />
                                        <div className="box-content-pro mt-2 pt-2 pb-2">
                                            <div className="sub-content-pr0 p-2">ข้อตกลงเเละเงื่อนไข</div>
                                        </div>
                                        <div className="text-left" style={{ color: "white" }}>
                                            <section
                                                dangerouslySetInnerHTML={{ __html: prodetail.detail }}
                                            />
                                        </div>
                                        <div className="card-footer" style={{ display: (prodetail.prolist == 5) ? "none" : "" }}>
                                            <button
                                                className="btn "
                                                style={{ fontWeight: "300", background: "radial-gradient(100% 100% at 50% 0, #f9cf95 0, #c69a5d 100%)", display: (username !== null) ? "" : "none" }}
                                                onClick={(e) => this.redeempro(e, prodetail.no)}
                                            >
                                                <span>รับโปรโมชั่น</span>
                                            </button>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footerMobileFixed" data-v-8c473eff="">
                    <div className="container" data-v-8c473eff="">
                        <div className="row" data-v-8c473eff="">
                            <div
                                className="col-sm-11 col-md-8 col-lg-6 offset-lg-3 col-md-6 offset-md-2 p-0"
                                data-v-8c473eff=""
                            >
                                <div
                                    className="x-button-actions"
                                    id="account-actions-mobile"
                                    data-v-8c473eff=""
                                >
                                    <div className="-outer-wrapper" data-v-8c473eff="">
                                        <div className="-right-wrapper" data-v-8c473eff="">
                                            <a
                                                // href="/deposit"
                                                onClick={(e) => {
                                                    this.checkurl(e, '/deposit')
                                                    //  window.location.href = '/'
                                                }}
                                                style={{ cursor: "pointer" }}
                                                className="-item-wrapper -line"
                                                rel="noopener noreferrer"
                                                data-v-8c473eff=""
                                            >
                                                <picture data-v-8c473eff="">
                                                    <source
                                                        type="image/webp"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/17a3aa7f-6d56-482e-8ceb-94de267f8100/public
                "
                                                        data-v-8c473eff=""
                                                    />
                                                    <source
                                                        type="image/png"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/17a3aa7f-6d56-482e-8ceb-94de267f8100/public
                "
                                                        data-v-8c473eff=""
                                                    />
                                                    <img
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/17a3aa7f-6d56-482e-8ceb-94de267f8100/public"
                                                        className="-ic-img"
                                                        width={43}
                                                        height={43}
                                                        data-v-8c473eff=""
                                                    />
                                                </picture>
                                                <span className="-text" data-v-8c473eff="">
                                                    ฝากเงิน
                                                </span>
                                            </a>
                                            <a
                                                //href="/withdraw"
                                                onClick={(e) => {
                                                    this.checkurl(e, '/withdraw')
                                                    //  window.location.href = '/'
                                                }}
                                                style={{ cursor: "pointer" }}
                                                className="-item-wrapper -affiliate"
                                                data-v-d4649762=""
                                                data-v-8c473eff=""
                                            >
                                                <picture data-v-d4649762="" data-v-8c473eff="">
                                                    <source
                                                        type="image/webp"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a94100fc-6087-45c8-4363-3d481a720700/public
                "
                                                        data-v-d4649762=""
                                                        data-v-8c473eff=""
                                                    />
                                                    <source
                                                        type="image/png"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a94100fc-6087-45c8-4363-3d481a720700/public
                "
                                                        data-v-d4649762=""
                                                        data-v-8c473eff=""
                                                    />
                                                    <img
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a94100fc-6087-45c8-4363-3d481a720700/public"
                                                        className="-ic-img"
                                                        width={34}
                                                        height={34}
                                                        data-v-d4649762=""
                                                        data-v-8c473eff=""
                                                    />
                                                </picture>
                                                <span className="-text" data-v-d4649762="" data-v-8c473eff="">
                                                    ถอนเงิน
                                                </span>
                                            </a>
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, '/')
                                                    //  window.location.href = '/'
                                                }}
                                                style={{ cursor: "pointer" }}
                                                className="-item-wrapper -line"
                                                rel="noopener noreferrer"
                                                data-v-8c473eff=""
                                            >
                                                <picture data-v-8c473eff="">
                                                    <source
                                                        type="image/webp"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/fcb38f58-a1b8-4d0e-f4f4-7e37596f6c00/public
                "
                                                        data-v-8c473eff=""
                                                    />
                                                    <source
                                                        type="image/png"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/fcb38f58-a1b8-4d0e-f4f4-7e37596f6c00/public
                "
                                                        data-v-8c473eff=""
                                                    />
                                                    <img
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/fcb38f58-a1b8-4d0e-f4f4-7e37596f6c00/public"
                                                        className="-ic-img"
                                                        width={45}
                                                        height={45}
                                                        data-v-8c473eff=""
                                                    />
                                                </picture>
                                                <span className="-text" data-v-8c473eff="">
                                                    หน้าเเรก
                                                </span>
                                            </a>
                                            <a
                                                // href="/event"
                                                onClick={(e) => {
                                                    this.checkurl(e, '/event')
                                                    //  window.location.href = '/'
                                                }}
                                                style={{ cursor: "pointer" }}
                                                className="-item-wrapper -line"
                                                rel="noopener noreferrer"
                                                data-v-8c473eff=""
                                            >
                                                <picture data-v-8c473eff="">
                                                    <source
                                                        type="image/webp"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2337e28c-ed76-45ad-0ae0-4168f49b9700/public
                "
                                                        data-v-8c473eff=""
                                                    />
                                                    <source
                                                        type="image/png"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2337e28c-ed76-45ad-0ae0-4168f49b9700/public
                "
                                                        data-v-8c473eff=""
                                                    />
                                                    <img
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2337e28c-ed76-45ad-0ae0-4168f49b9700/public"
                                                        className="-ic-img"
                                                        width={34}
                                                        height={34}
                                                        data-v-8c473eff=""
                                                    />
                                                </picture>
                                                <span className="-text" data-v-8c473eff="">
                                                    กิจกรรม
                                                </span>
                                            </a>
                                            <a
                                                style={{ cursor: "pointer" }}
                                                href={this.state.linelink}
                                                className="-item-wrapper -line"
                                                rel="noopener noreferrer"
                                                data-v-8c473eff=""
                                            >
                                                <picture data-v-8c473eff="">
                                                    <source
                                                        type="image/webp"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3bce3047-5677-4a9c-0676-91bd73fa9600/public
                "
                                                        data-v-8c473eff=""
                                                    />
                                                    <source
                                                        type="image/png"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3bce3047-5677-4a9c-0676-91bd73fa9600/public
                "
                                                        data-v-8c473eff=""
                                                    />
                                                    <img
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3bce3047-5677-4a9c-0676-91bd73fa9600/public"
                                                        className="-ic-img"
                                                        width={34}
                                                        height={34}
                                                        data-v-8c473eff=""
                                                    />
                                                </picture>
                                                <span className="-text" data-v-8c473eff="">
                                                    Line
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Main;
