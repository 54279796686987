import React, { Component } from 'react'; //different
import './App.css';
import Carousel from './carousel';
class App extends Component {


    onClick = (e) => {
        e.preventDefault()
        ////console.log('onclick..')
    }
    render() {
        return (
            <>
                     <div style={{ display: this.state.tabselect == 0 ? "" : "none" }}>
                                <div className="row m-1" >
                                    <div className="p-0 text-center animated animatedFadeInUp fadeInUp col-12">
                                        <img
                                            role="button"
                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7491bf17-d33b-41d9-7e57-fbfa016fca00/public"
                                            className="border-home p-1 w-100 img-home-category"
                                            alt=""
                                        />
                                    </div>
                                    <a
                                        onClick={(e) => {
                                            window.location.href = '/casino'
                                        }}
                                        className="p-0 text-center animated animatedFadeInUp fadeInUp col-4 p-1">
                                        <img
                                            role="button"
                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a445b697-e32e-4891-9f42-43b9ce106b00/public"
                                            className="w-100 img-home-category"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            window.location.href = '/gamehit'
                                        }}
                                        className="p-0 text-center animated animatedFadeInUp fadeInUp col-4 p-1">
                                        <img
                                            role="button"
                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7712be96-7222-429b-d014-e3e374820300/public"
                                            className="w-100 img-home-category"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            window.location.href = '/fishing'
                                        }}
                                        className="p-0 text-center animated animatedFadeInUp fadeInUp col-4 p-1">
                                        <img
                                            role="button"
                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7b1f4201-b192-4d4b-10e1-f2d70fd22b00/public"
                                            className="w-100 img-home-category"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            window.location.href = '/slot'
                                        }}
                                        className="p-0 text-center animated animatedFadeInUp fadeInUp col-4 p-1">
                                        <img
                                            role="button"
                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/4d793898-7785-4245-1081-e29928808e00/public"
                                            className="w-100 img-home-category"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            window.location.href = '/sport'
                                        }}
                                        className="p-0 text-center animated animatedFadeInUp fadeInUp col-4 p-1">
                                        <img
                                            role="button"
                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/38a154b4-4668-442b-7f56-ec992c2eca00/public"
                                            className="w-100 img-home-category"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                    className="p-0 text-center animated animatedFadeInUp fadeInUp col-4 p-1">
                                        <img
                                            role="button"
                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/9cc6e8ba-d55b-4faa-cf35-76b88db9bd00/public"
                                            className="w-100 img-home-category"
                                            alt=""
                                        />
                                    </a>
                                </div>
                                <div>
                                    <main className="container p-0">
                                        <div className="p-1 mb-2 text-left container-xl text-white mt-1 my5 animated animatedFadeInUp fadeInUp">
                                            <div>
                                                <img
                                                    className="w-100 mt-2 mb-2 responsive-line"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d289b84a-2fc8-413e-e589-d5570ca44a00/public"
                                                />
                                            </div>
                                            <div className="clearfix">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h4 className="ps-2 mb-0 pt-1 f-22 text-title">
                                                            <b>เกมส์ยอดนิยม</b>
                                                        </h4>
                                                    </div>
                                                    <div>
                                                        <img
                                                            role="button"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2868e8f7-a36f-48a6-bbe4-e71172fc4c00/public"
                                                            alt=""
                                                            className="w-icon-cr-bl-record-all"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-center text-white">loading...</div>
                                        </div>
                                    </main>
                                </div>
                                {/**/}
                                <div className="game-show">
                                    <div>
                                        <div className="p-2" data-v-d899973a="">
                                            <div className="clearfix" data-v-d899973a="">
                                                <div className="row m-0" data-v-d899973a="">
                                                    <div
                                                        className="col-md-12 col-12 text-left pl-0 pr-0"
                                                        data-v-d899973a=""
                                                    >
                                                        <h4
                                                            className="ps-2 mb-0 pt-1 f-22 text-title"
                                                            data-v-d899973a=""
                                                        >
                                                            <b data-v-d899973a="">บทความ </b>
                                                            <span className="text-green" data-v-d899973a="">
                                                                <img
                                                                    className="img-title-web"
                                                                    src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                                                                    alt=""
                                                                    data-v-d899973a=""
                                                                />
                                                            </span>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-1" data-v-d899973a="">
                                                <div className="row pb-3" data-v-d899973a="">
                                                    <div className="col-12" data-v-d899973a="">
                                                        <img
                                                            className="w-100 border-article"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/fbfd457a-512e-4a35-54ca-5cfc6f205c00/public"
                                                            alt=""
                                                            data-v-d899973a=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-article" data-v-d899973a="">
                                                    <h5 data-v-d899973a="">
                                                        <strong data-v-d899973a="">บทความโรลริ่ง 1% </strong>
                                                    </h5>
                                                    <p data-v-d899973a="">
                                                        ต้องดูแทงบาคาร่าเสียเงินแต่ผลประกอบการคือกำไร เพราะรู้จัก
                                                        Rolling 1%
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        ในวันนี้เราจะมาอธิบายให้ทุกคนที่เล่นบาคาร่าได้รู้จักหัวใจที่สำคัญที่สุด
                                                        ที่มันจะช่วยเพิ่มเปอร์เซ็นต์ชนะ และลดการขาดทุน&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        ที่คนส่วนใหญ่ไม่เคยรู้ นั่นคือ Rolling อัตโนมัติ 1%
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        Rolling คือศัพท์ในวงการบาคาร่า ถ้าจะแปลง่ายๆก็คือค่าคอมมิชชั่น
                                                        ที่ทุกคนจะได้รับกลับคืนมานั่นเอง ถ้าไพ่บาคาร่าโอกาสแพ้ชนะคือ
                                                        50 50&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        งั้นเราจะเอากฎ 50 นี้มาใช้กัน โดยทุกเกมที่แทง คือแทงที่ราคา
                                                        1,000 บาท เกมที่ 1 แทง Player ชนะ เกมที่ 2 แทง Player
                                                        แพ้&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        เกมที่ 3 แทงแบงค์เกอร์ชนะ และเกมที่ 4 แทงแบงค์เกอร์แพ้
                                                        เมื่อครบ 4 เกม เราจะขาดทุนทันที 50 บาท
                                                        เพราะเกมที่เราแทงแบงค์เกอร์ชนะเราจะโดนหักไป 5% นั่นเอง
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        &nbsp;แต่พอเรารู้จัก Rolling 1% เราจะได้ค่า Rolling
                                                        กลับคืนมาถึง 40 บาท ทำให้ จากกจะขาดทุน 50 บาท กลายเป็นขาดทุน
                                                        10 บาทเท่านั้น
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        &nbsp;นั่นเท่ากับว่าโลลิ่ง 1%
                                                        สามารถเซฟการขาดทุนของคุณได้มากถึง 80% เลยทีเดียว 80%
                                                        เลยทีเดียว นี่เป็นแค่ตัวอย่างเท่านั้น
                                                        แต่มีอีกหลายเหตุการณ์ที่วันนี้ ไม่ใช่วันของคุณ
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        คุณเสียเงินให้บาคาร่า แต่ผลประกอบการคุณ กลับเป็นบวก เพราะมี
                                                        Rolling มาช่วย
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        ผมขออธิบายก่อนนะครับ คำว่าจำนวนหมายถึงยอดเล่นทั้งหมด
                                                        และคำว่าจำนวนที่ถูกต้องหมายถึง ยอดแพ้ชนะ
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        พูดง่ายๆคือหักยอดเสมอไป และยอดตรงนี้ล่ะครับที่จะมาคำนวณเป็น
                                                        Rolling ส่วนการนับเดิมพันหมายถึง
                                                        จำนวนเกมที่คุณเล่นไปในวันนั้น&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        เช่นถ้าคุณแทงน้ำเงิน 5 เกม แดง 5 เกม
                                                        การนับเดิมพันคุณก็จะเป็น10เป็นต้น ตรงชนะแพ้หมายถึง
                                                        วันนี้คุณได้หรือเสีย&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        คอมมิชชั่นตรงนี้แหละครับคือ Rolling ที่คุณได้รับในวันนี้
                                                        และส่วนสุดท้ายก็คือเอายอดชนะแพ้ มาหักลบกับค่าคอมนั่นเอง
                                                    </p>
                                                </div>
                                                <br data-v-d899973a="" />
                                                <div className="text-3" data-v-d899973a="" />
                                                <div className="row pb-3" data-v-d899973a="">
                                                    <div className="col-12" data-v-d899973a="">
                                                        <img
                                                            className="w-100 border-article"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/31e68827-fa81-49eb-89f6-4fb831c4dc00/public"
                                                            alt=""
                                                            data-v-d899973a=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-1 text-article" data-v-d899973a="">
                                                    <h5 data-v-d899973a="">
                                                        <strong data-v-d899973a="">
                                                            เผยสูตรเล่นคาสิโนตามแบบฉบับเซียนพนันสายทำเงิน{" "}
                                                        </strong>
                                                    </h5>
                                                    <p data-v-d899973a="">
                                                        &nbsp; &nbsp;
                                                        &nbsp;คาสิโนถือว่าเป็นสถานที่ที่รวบรวมเกมพนันไว้มากมายกว่า 10
                                                        ชนิด ปัจจุบันได้ถูกนำมาพัฒนาให้สามารถเล่นบนเว็บไซต์ได้อีกด้วย
                                                        จึงทำให้มีนักเสี่ยงโชคหลายคนอยากทำเงินหรือได้เงินดูสักครั้ง
                                                        วันนี้เราจะมาเผยสูตรเล่นคาสิโนตามแบบฉบับเซียนพนันสายทำเงิน
                                                        จะมีสูตรอะไรกันบ้างไปดูกันเลย&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        1. กำหนดเงินทุน ไม่ว่าคุณจะมีเงินทุนมากน้อยเพียงใด
                                                        ก็สามารถทำเงินได้หากรู้จักกำหนดเงินทุน
                                                        ทำให้ผู้เล่นรู้ว่าต้องแทงเท่าไร แทงเมื่อไหร่ แทงตอนไหน
                                                        หรือเล่นกี่ครั้งจึงจะได้กำไรกลับมา
                                                        ช่วยปรับให้การเล่นเป็นขั้นเป็นตอนมากยิ่งขึ้น
                                                        เมื่อเปรียบเทียบกับการเล่นไปเรื่อยโดยที่ไม่มีจุดหมาย
                                                        พบว่าทำเงินได้แน่นอนว่าและมีโอกาสน้อยที่จะขาดทุน&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        2. มองหาจุดอ่อน
                                                        เชื่อว่าหรือว่าการพนันแทบทุกชนิดมีจุดอ่อนกันทั้งนั้น
                                                        เพียงแต่ต้องใช้ประสบการณ์ในการเล่น
                                                        เพื่อทำให้มองหาจุดอ่อนเหล่านี้ได้อย่างทะลุปุโปร่ง
                                                        จุดอ่อนเหล่านี้ช่วยทำให้เราแทงพนันได้อย่างถูกต้อง แม่นยำ
                                                        และทำกำไรได้ในระยะเวลาอันสั้น
                                                        สำหรับท่านที่หาไม่ได้สามารถหาได้จากเว็บไซต์คาสิโนออนไลน์
                                                        หรือสอบถามจากเซียนพนันมืออาชีพโดยตรง&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        3. เรียนรู้วิธีการเล่น
                                                        สูตรนี้อาจเป็นสูตรที่ฟังดูง่ายๆแต่นักพนันหลายคนอาจจะนึกไม่ถึง
                                                        แต่รู้หรือไม่ว่ายิ่งเรียนรู้วิธีการเล่นมากเท่าไร เช่น
                                                        วิธีการนับแต้ม วิธีการเดิมพัน ขั้นต่ำในการเดิมพัน วิธีจั่วไพ่
                                                        หรือวิธีแทงพนัน ยิ่งช่วยเพิ่มโอกาสทำเงินมากเท่านั้น
                                                        ลดความสับสนระหว่างเล่น ทำให้เดิมพันได้อย่างไม่ใจ
                                                        และป้องกันไม่ให้เสียพนันโดยไม่ตั้งใจ
                                                        ฉะนั้นควรเวลาทบทวนและศึกษาวิธีการเล่นอย่างต่อเนื่อง&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        4. ไม่ใช้อารมณ์
                                                        รู้หรือไม่ว่าอารมณ์เป็นศัตรูตัวสำคัญที่ทำให้เสียพนันได้
                                                        ไม่ว่าคุณจะได้เงิน เสียพนัน หรือแพ้จนแทบจะหมดตัว
                                                        ไม่ควรนำเอาความโกรหรือความแค้นมาเป็นส่วนหนึ่งของเกมเล่น
                                                        ควรวางมันไว้นอกโต๊ะนอกเกมให้หมด
                                                        พวกนี้อาจทำให้เสียพนันหรือนำไปสู่การหมดเนื้อหมดตัวได้ในอนาคต&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        5. เล่นให้บ่อยที่สุด ไม่มีใครเก่งมาตั้งแต่เกิด
                                                        คำนี้น่าจะพอทำให้เห็นภาพมากยิ่งขึ้น
                                                        ฉะนั้นควรใช้เวลาในการเล่นให้มากที่สุด
                                                        ยิ่งเล่นบ่อยยิ่งทำให้มองเห็นโอกาส มองเห็นจุด
                                                        และทำความเข้าใจไปพร้อมๆกัน
                                                        สูตรเล่นคาสิโนที่เราได้นำมาแนะนำกันในวันนี้
                                                        นำมาจากเซียนพนันสายทำเงินโดยตรง
                                                        จึงสามารถรับประกันว่ามันช่วยทำเงินได้จริง ๆ
                                                        ยิ่งนำไปปรับเปลี่ยนกับการเล่นของตนเองได้ไวเท่าไร
                                                        ยิ่งหาเงินกำไรได้รวดเร็วมากขึ้นเท่านั้น
                                                        สามารถนำไปใช้เล่นบนเว็บคาสิโนออนไลน์ทุกเว็บ
                                                        ไม่ได้ผิดกฏแถมยังเป็นตัวช่วยชั้นดีที่ไม่ต้องเสียเงินเพิ่มใดๆ
                                                    </p>
                                                </div>
                                                <br data-v-d899973a="" />
                                                <div className="row pb-3" data-v-d899973a="">
                                                    <div className="col-12" data-v-d899973a="">
                                                        <img
                                                            className="w-100 border-article"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/53aefd90-f48a-428e-5580-0ae761b25200/public"
                                                            alt=""
                                                            data-v-d899973a=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-2 text-article" data-v-d899973a="">
                                                    <h5 data-v-d899973a="">
                                                        <strong data-v-d899973a="">
                                                            สิทธิพิเศษที่หาได้จากการเล่นคาสิโนออนไลน์เท่านั้น{" "}
                                                        </strong>
                                                    </h5>
                                                    <p data-v-d899973a="">
                                                        &nbsp; &nbsp; &nbsp;คาสิโนออนไลน์ คือ
                                                        สถานที่เดิมพันแห่งใหม่ที่ได้รับความนิยมอย่างมากในยุคนี้
                                                        อัดแน่นไปด้วยการพนันมากมายหลายชนิด เช่น บาคาร่า
                                                        รูเล็ตโป๊กเกอร์ แบล็คแจ็ค ป๊อกเด้ง เสือมังกร สล็อต
                                                        หรือการพนันกีฬาอีกมากมาย ทั้งหมดนี้สามารถหาเล่นได้จบในที่เดียว
                                                        &nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        &nbsp; &nbsp;
                                                        &nbsp;ปัจจุบันยิ่งเป็นยุคที่อินเตอร์เน็ตหรือสื่อออนไลน์เข้ามามีบทบาทเท่าไร
                                                        ยิ่งเพิ่มจำนวนของผู้เล่นสูงขึ้น
                                                        เพราะมันสะดวกสบายไม่ต้องเสียเวลาเดินทาง
                                                        มีระบบสมาชิกที่ปลอดภัยไร้กังวล
                                                        และเงินรางวัลจำนวนมากที่กำลังรออยู่
                                                        แต่ไม่ได้มีเพียงแต่เหตุผลทั้งหมดที่เราแนะนำไว้ข้างต้น
                                                        เพราะวันนี้เราได้รวบรวมสิทธิพิเศษที่สามารถหาได้จากการเล่นออนไลน์มาฝากกัน
                                                        &nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        1.เล่นได้ตลอด 24 ชั่วโมง สิทธิพิเศษแรกที่ไม่มีใครเหมือนเลย คือ
                                                        ทุกท่านสามารถเล่นได้ตลอด 24 ชั่วโมง ไม่ว่าจะอยู่ที่้บ้าน
                                                        อยู่ที่ทำงาน หรือขณะเดินทางไปไหน ทุกเกม ทุกระบบ
                                                        ทุกบริการพร้อมเปิดใช้งาน พยายามปรับปรุงเว็บไซต์ให้ดีอยู่เสมอ
                                                        ไม่ให้มีปัญหาติดขัด
                                                        และสร้างความเพลิดให้กับผู้เล่นที่เข้ามาเสมอ&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        2.โปรโมชั่นเพียบ
                                                        นี่คือสิ่งที่คาสิโนออนไลน์สามารถสร้างความโดดเด่นได้เป็นอย่าง
                                                        เป็นโปรโมชั่นที่ถูกสร้างสรรค์เพื่อมอบโบนัสต่างๆให้ผู้เล่นมากมาย
                                                        เริ่มต้นตั้งแต่โปรโมชั่นสมัครสมาชิกใหม่ ให้โบนัสสูงสุดถึง 50%
                                                        จากยอดสมัครครั้งแรก, โปรโมชั่นเงินฝากประจำวัน
                                                        มอบโบนัสสูงสุดกว่า 20%
                                                        ให้กับนักพนันที่มียอดฝากแรกเป็นประจำทุกวัน,
                                                        โปรโมชั่นคืนยอดเสีย
                                                        มอบเงินคืนให้กับยอดเสียรวมรายสัปดาห์ให้กับนักพนันโชคร้าย
                                                        หรือโปรโมชั่นชวนเพื่อนสมัครสมาชิก
                                                        ยิ่งชวนมากยิ่งได้เงินโบนัสมากเช่นกัน เป็นต้น
                                                        เป็นตัวช่วยสำคัญที่ทำให้เงินทุนเพิ่มขึ้น
                                                        โอกาสทำเงินทำไรสูงกว่าเก่า
                                                        และลดโอกาสเสียพนันให้น้อยลงอีกด้วย&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        3.ดาวน์โหลดลงมือถือได้ สำหรับท่านที่มี มือถือ สมาร์ทโฟน
                                                        หรือแทปเล็ต ทุกรุ่น ทั้งจาก Andriod หรือ iOS
                                                        สามารถดาวน์โหลดมาติดตั้งได้แล้ววันนี้
                                                        ช่วยเพิ่มความสะดวกสบายได้มากกว่าที่เคยสัมผัสมา
                                                        สามารถเล่นได้ทุกเกมจากเว็บคาสิโนออนไลน์ได้ตามปกติ
                                                        แถมยังมีโบนัสอื่น ๆ สำหรับผู้ที่เล่นผ่านมือให้อีกด้วย
                                                        ลองนึกภาพว่าคุณสามารถพกพาบาคาร่า โป๊กเกอร์ แบล็คแจ็ค
                                                        หรือสล็อตออนไลน์ไว้เล่นยามเงายามเบื่อจะดีแค่ไหน
                                                        สิทธิพิเศษนี้สามารถตอบโจทย์ได้เป็นอย่างดี&nbsp;
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        &nbsp; &nbsp;
                                                        &nbsp;ปัจจุบันคาสิโนออนไลน์ยินดีที่จะเพิ่มสิทธิพิเศษใหม่ๆให้กับนักพนันได้รับความประทับมากยิ่งขึ้น
                                                        ท่านที่ยังไม่เคยเล่นผ่านสถานที่รูปแบบใหม่นี้มาก่อน
                                                        แนะนำว่าไม่ควรพลาดอย่างยิ่ง สมัครวันนี้ สนุกได้วันนี้
                                                        และรับสิทธิพิเศษได้วันนี้ ไม่ต้องรอไม่ลุ้นอีกต่อไป
                                                        รับประกันว่าไม่มีทางหาได้จากสถานที่เล่นพนันที่อื่นแน่นอน
                                                    </p>
                                                </div>
                                                <br data-v-d899973a="" />
                                                <div className="row pb-3" data-v-d899973a="">
                                                    <div className="col-12" data-v-d899973a="">
                                                        <img
                                                            className="w-100 border-article"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8236db76-06f0-4a0e-e839-a484b7ae8100/public"
                                                            alt=""
                                                            data-v-d899973a=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-3 text-article" data-v-d899973a="">
                                                    <h5 data-v-d899973a="">
                                                        <strong data-v-d899973a="">
                                                            ในบทความนี้เราจะพาทุกท่านไปสอนแบบเจาะลึกถึง วิธีแทงบอล
                                                            พร้อมสอนแบบจับมือทำ พร้อมแล้วไปลุยกันเลย
                                                        </strong>
                                                    </h5>
                                                    <p data-v-d899973a="">4 ข้อควรรู้ก่อนเข้าสู่ วิธีแทงบอล</p>
                                                    <p data-v-d899973a="">
                                                        1. เริ่มต้นด้วยการเลือกเว็บไซต์แทงบอล จุดเริ่มต้นของทุก
                                                        วิธีการแทงบอล คือการเลือกเว็บไซต์พนันบอลให้ถูก
                                                        นักพนันจะต้องรู้จักเลือกเว็บไซต์ที่จะใช้บริการก่อน
                                                        โดยเว็บนั้นจะต้องมาพร้อมบริการคุณภาพ
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        2. รู้จักรูปแบบการวางเดิมพันฟุตบอลทั้ง 6 รูปแบบ
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        รูปแบบของการเดิมพันถือเป็นจุดเด่นของการแทงบอลเลยก็ว่าได้
                                                        เพราะการเดิมพันฟุตบอลออนไลน์นั้น
                                                        นักเดิมพันสามารถเลือกเดิมพันได้หลากหลายรูปแบบ
                                                        ซึ่งรูปแบบที่ได้รับความนิยม
                                                    </p>
                                                    <p data-v-d899973a="">
                                                        3. รู้จักความแตกต่างระหว่างการแทงบอลแบบเต็งและแบบสเต็ป
                                                        นักเดิมพันที่เป็นมือใหม่
                                                        อาจจะเคยได้ยินคำว่าการเดิมพันฟุตบอลออนไลน์แบบเต็ง
                                                        และการเดิมพันฟุตบอลออนไลน์แบบสเต็ปกันมาบ้างแล้ว
                                                        ซึ่งการเดิมพันฟุตบอลออนไลน์ทั้ง 2 รูปแบบ มีความแตกต่างกัน
                                                    </p>
                                                    <p data-v-d899973a="">4. รู้จัก วิธีแทงบอล</p>
                                                    <p data-v-d899973a="">
                                                        เมื่อนักเดิมพันได้ทำความเข้าใจเกี่ยวกับการรายละเอียดของการแทงบอลออนไลน์แล้ว
                                                        ก็ควรได้ทราบถึงขั้นตอนการแทงบอลออนไลน์ด้วยเช่นเดียวกัน
                                                    </p>
                                                </div>
                                                <br data-v-d899973a="" />
                                                <div className="row pb-3" data-v-d899973a="">
                                                    <div className="col-12" data-v-d899973a="">
                                                        <img
                                                            className="w-100 border-article"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/c4bf9747-a857-4704-adee-41d977ae8600/public"
                                                            alt=""
                                                            data-v-d899973a=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            </>

        );
    }
}
export default App;
