
import React, { Component } from 'react'; //different
//import './style.css';
import Login from './login';
import instance from "./axios-instance";
import Systems from "./SystemFunction";
import NavMenu from './navmenu';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            linelink: "",
        }
    }
    async componentDidMount() {
        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                this.setState({
                    linelink: datas[1].value,
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });

    }
    render() {
        return (
            <>
                <div className="col-sm-11 col-md-8 col-lg-6 offset-lg-3 col-md-6 offset-md-2 p-0 bg-header-full">

                    <div className="mt-1">
                        <div className="animated animatedFadeInUp fadeInUp m-3">
                            <div className="container">
                                <h3 className="text-font-title-signin text-white">
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                                        className="logo-w-signIn"
                                        alt=""
                                    />
                                </h3>
                                <br />
                                <div className="x-modal-separator-container x-login-form animated animatedFadeInUp fadeInUp bg-signIn">
                                    
                                    <div className="-top">
                                        <div
                                            data-animatable="fadeInModal"
                                            className="animated fadeInModal"
                                        >
                                            <hr className="style-one" />
                                            <div className="-img-container text-white text-center">
                                                <h6>เข้าระบบด้วยเบอร์โทรศัพท์</h6>
                                            </div>
                                            <Login/>
                                        </div>
                                        <div
                                            className="mt-1 mb-1 w-100"
                                            style={{ textAlign: "center" }}
                                        >
                                            <h3 className="title-footer-wrap pt-2 text-white">
                                                <b>Language / ภาษา / ဘာသာစကား </b>
                                            </h3>
                                            <ul className="logo-list-footer -casino">
                                                <li role="button">
                                                    <img
                                                        className="lang-img active-lang"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e1626da-cc1f-451f-838f-54e5e1848f00/public"
                                                        alt=""
                                                    />
                                                </li>
                                                {/* <li role="button">
                                                            <img
                                                                className="lang-img"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/08ef9747-bd58-4185-6331-7441b89cf000/public"
                                                                alt=""
                                                            />
                                                        </li>
                                                        <li role="button">
                                                            <img
                                                                className="lang-img"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a50a5057-212d-4e4d-f94b-8d1f02173600/public"
                                                                alt=""
                                                            />
                                                        </li>
                                                        <li role="button">
                                                            <img
                                                                className="lang-img"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7bd877b2-2dd7-45e8-3246-b38ea4d77f00/public"
                                                                alt=""
                                                            />
                                                        </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="row m-0">
                                        <div className="col-6 col-md-6">
                                            <a    href="/register" className="btn -submit js-submit-accept-term rounded-pill v-login mt-lg-1 mt-0 w-100 p-2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={15}
                                                    height={15}
                                                    fill="currentColor"
                                                    className="bi bi-person-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                                </svg>
                                                <span>สมัครสมาชิก</span>
                                            </a>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <a href={this.state.linelink} className="btn -submit js-submit-accept-term rounded-pill v-login-contact mt-lg-1 mt-0 w-100 p-2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={10}
                                                    height={10}
                                                    fill="currentColor"
                                                    className="bi bi-telephone-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                                                    />
                                                </svg>
                                                <span>ติดต่อ</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
export default App;
