import React, { Component } from 'react'; //different
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
//import './style.css';
import Main from './main';
import Casino from './casino';
import NavMenu from './navmenu';
import Slot from './slot';
import Sport from './sport';
import Fising from './fising';
import Banner from './banner';
import TextSlider from './textslide';
class App extends Component {
  render() {
    return (

      <div className="d-none d-lg-block">
        <div>
          <br />
          <br />
          <br />
        </div>
        {/**/}
        <div>
          <Banner />
        </div>
        <div >
          <TextSlider />
        </div>
        {/**/}
      </div>


    );
  }
}

export default App;
